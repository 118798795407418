export class CuerpoDialogCrudRead {
  titulo!: string;
  subtitulo!: string;
  icono!: string;
  actionHandler!: () => void;
  roles!: string[];
  datos1!: DatoDialogCrudRead[];
  datos2!: DatoDialogCrudRead[];

  constructor(

  ) {
  }
}

export class DatoDialogCrudRead {
  nombre!: string;
  valor!: string;
  propertyPipe!: string;

  constructor() {

  }
}
