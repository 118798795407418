<div *appRole="['Usuarios.Eliminar']">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
    <h1 mat-dialog-title>
        Eliminar usuario <strong>{{ data.userName }}</strong>
    </h1>
    <div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
        ¿Esta seguro que desea eliminar este registro?
    </div>
    <mat-divider></mat-divider>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="warn" (click)="eliminarUsuario()">
      <mat-icon>delete</mat-icon>
      <span>Eliminar</span>
    </button>
        <button mat-button cdkFocusInitial (click)="onNoClick()"><span>Cancelar</span></button>
    </div>
</div>
