<mat-form-field appearance="outline" class="w-100">
  <ngx-spinner
    [zIndex]="-1"
    name="spBusqueda"
    bdColor="rgba(255,255,255,0.8)"
    size="small"
    color=" #009688"
    type="line-scale"
    [fullScreen]="false"
  ></ngx-spinner>
  <mat-label>Casos</mat-label>
  <input
    [disabled]="disable"
    type="search"
    placeholder="Buscar por nombre o doc."
    matInput
    [matAutocomplete]="auto"
    [formControl]="entityControl"
  />
  <button disabled mat-icon-button matSuffix>
    <mat-icon>search</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      *ngFor="
        let caso of filteredCaso | async;
        let i = index;
        trackBy: trackByItems
      "
      [value]="caso.descripcion"
      (click)="selectValue(caso)"
      (onSelectionChange)="selectValue(caso)"
    >
      <span
        #tooltip="matTooltip"
        matTooltipPosition="right"
        matTooltip="{{ caso.descripcion }}"
        >{{ caso.descripcion | titlecase }}</span
      >
    </mat-option>
  </mat-autocomplete>
  <button
    mat-icon-button
    matSuffix
    (click)="actualizarCasos()"
    matTooltip="Actualizar"
    matTooltipPosition="below"
  >
    <mat-icon color="primary">sync_alt</mat-icon>
  </button>
  <button
    mat-icon-button
    matSuffix
    (click)="openDialog('Agregar', {})"
    matTooltip="Nuevo"
    matTooltipPosition="below"
  >
    <mat-icon color="primary">add</mat-icon>
  </button>
  <button
    mat-icon-button
    matSuffix
    matTooltip="Limpiar"
    *ngIf="entitySelect"
    (click)="selectValue(null)"
  >
    <mat-icon>clear</mat-icon>
  </button>
  <button
    mat-icon-button
    matSuffix
    matTooltip="Limpiar"
    *ngIf="entitySelect"
    (click)="selectValue(null)"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-form-field>
