<mat-card>
  <mat-card-content *appRole="['ExpedientesPartes.Crear']">
    <form [formGroup]="expedienteParteForm">
      <div class="row">
        <div class="col-lg-4 col-sm-12">
          <app-buscar-persona
            (eventEntity)="seleccionPersona($event)"
          ></app-buscar-persona>
        </div>
        <div class="col-lg-2 col-sm-12">
          <mat-form-field appearance="outline" class="w-100">
            <ngx-spinner
              [zIndex]="-1"
              name="spTipoParte"
              bdColor="rgba(255,255,255,0.8)"
              size="small"
              color=" #009688"
              type="line-scale"
              [fullScreen]="false"
            ></ngx-spinner>
            <mat-label>Tipo parte</mat-label>
            <mat-select
              placeholder="Seleccione opcion"
              formControlName="TipoParte"
            >
              <mat-option
                [value]="tipoParte.id"
                *ngFor="let tipoParte of tiposParte"
              >
                {{ tipoParte.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-4 col-sm-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Descripción</mat-label>
            <input matInput type="text" formControlName="Descripcion" />
          </mat-form-field>
        </div>
        <div class="col-lg-2 col-sm-12">
          <button
            mat-raised-button
            color="primary"
            style="margin-right: 3px"
            name="Agregar"
            [disabled]="!expedienteParteForm.valid"
            (click)="onSubmit()"
          >
          <span>Agregar</span>
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-content *appRole="['ExpedientesPartes.Listar']">
    <ngx-spinner
      [zIndex]="100"
      name="spExpedienteParte"
      bdColor="rgba(255,255,255,0.8)"
      size="default"
      color=" #009688"
      type="ball-atom"
      [fullScreen]="false"
    ></ngx-spinner>
    <table
      class="mat-elevation-z8"
      mat-table
      [dataSource]="dataSource"
      *ngIf="data"
    >
      <ng-container matColumnDef="tipoParte">
        <th mat-header-cell *matHeaderCellDef><strong>Tipo Parte</strong></th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.tipoParte.descripcion }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="persona">
        <th mat-header-cell *matHeaderCellDef><strong>Persona</strong></th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.persona.nombreCompleto ? "" : element.persona.nombreCompleto | titlecase }}</span
          ><button
            class="icon-display"
            mat-icon-button
            (click)="verInfoPersona(element.persona)"
            matTooltip="Info. de la persona"
          >
            <mat-icon>info</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="descripcion">
        <th mat-header-cell *matHeaderCellDef><strong>Descripción</strong></th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.descripcion }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="accion" stickyEnd>
        <th mat-header-cell *matHeaderCellDef><strong>Acción</strong></th>
        <td mat-cell *matCellDef="let element" class="action-link">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              [routerLink]="['/personas/', element.persona.id]"
              *appRole="['Personas.Ver']"
            >
              <mat-icon>visibility</mat-icon>
              <span>Ver persona</span>
            </button>
            <button
              mat-menu-item
              [routerLink]="['/personas/edit/', element.persona.id]"
              *appRole="['Personas.Modificar']"
            >
              <mat-icon>edit</mat-icon>
              <span>Editar persona</span>
            </button>
            <button mat-menu-item (click)="openDialog('Eliminar', element)">
              <mat-icon>delete</mat-icon>
              <span>Eliminar</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumnsExpedienteParte"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumnsExpedienteParte"
      ></tr>
    </table>
  </mat-card-content>
</mat-card>
