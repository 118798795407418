<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button
      mat-icon-button
      (click)="toggleDrawer()"
      class="example-icon"
      aria-label="Example icon-button with menu icon"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <span>GPerito</span>
    <span class="example-spacer"></span>
    <!-- <app-buscar-general></app-buscar-general>
    <app-btn-notificaciones></app-btn-notificaciones> -->
    <nav-search-perfil-logo></nav-search-perfil-logo>
  </mat-toolbar-row>
</mat-toolbar>

<mat-drawer-container autosize>
  <mat-drawer #drawer mode="over" position="start">
    <!-- Contenido del Drawer -->
    <app-logo *ngIf="archivo.id != 0" [idArchivo]="archivo.id"></app-logo>

    <!-- Menu items -->
    <div *ngIf="!isLoading">
      <div
        *ngFor="let item of menues; let i = index; trackBy: trackByItems"
        class="menu-item-container"
      >
        <!-- Menú con submenús -->
        <div *ngIf="item.submenues && item.submenues.length > 0">
          <button mat-menu-item [matMenuTriggerFor]="menu" class="menu-button">
            <mat-icon>{{ item.iconName }}</mat-icon>
            {{ item.displayName }}
          </button>

          <mat-menu #menu="matMenu">
            <div
              *ngFor="let subItem of item.submenues"
              class="menu-item-container"
            >
              <button
                mat-menu-item
                [routerLink]="'/' + subItem.route"
                class="menu-button"
                (click)="toggleDrawer()"
              >
                <mat-icon>{{ subItem.iconName }}</mat-icon>
                {{ subItem.displayName }}
              </button>
            </div>
          </mat-menu>
        </div>

        <!-- Menú sin submenús -->
        <div *ngIf="!item.submenues || item.submenues.length === 0">
          <button
            mat-menu-item
            [routerLink]="'/' + item.route"
            class="menu-button"
            (click)="toggleDrawer()"
            [class.selected]="isSelected(item)"
          >
            <mat-icon>{{ item.iconName }} </mat-icon>
            {{ item.displayName }}
          </button>
        </div>
      </div>
    </div>
  </mat-drawer>

  <mat-drawer-content>
    <!-- <app-breadcrumb></app-breadcrumb> -->

    <!-- Contenido principal -->
    <div class="container-fluid p-3 my-3">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
