<div style="position: relative">
  <ngx-spinner
    name="spArchivos"
    bdColor="rgba(255,255,255,0.8)"
    size="small"
    color=" #009688"
    type="line-scale"
    [fullScreen]="false"
  ></ngx-spinner>
  <div class="div-logo">
    <div>
      <!-- <mat-icon class="div-logo-icono" *ngIf="!imgURL">image</mat-icon> -->
      <img class="logo" [src]="imgURL" *ngIf="imgURL" />
    </div>
  </div>
</div>
