import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '@modules/shared.module';

@Component({
  selector: 'app-button-action',
  styleUrls: ['./button-action.component.css'],
  templateUrl: './button-action.component.html',
  standalone:  true,
  imports:[SharedModule]
})
export class ButtonActionComponent {
  @Input() actions: { label: string; icon: string; handler: () => void; roles: string[] }[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}
