<h1 mat-dialog-title>
  Acción realizada :: <strong>{{ local_data.action }}</strong>
</h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <ngx-spinner
    [zIndex]="100"
    name="spLoadGeneric"
    bdColor="rgba(255,255,255,0.8)"
    size="default"
    color=" #009688"
    type="ball-atom"
    [fullScreen]="false"
  >
  </ngx-spinner>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12" *ngIf="local_data.action != 'Eliminar'">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Nombre</mat-label>
          <input
            type="text"
            placeholder="Nombre"
            formControlName="Nombre"
            matInput
            [disabled]="local_data.action == 'Ver'"
          />
          <mat-error *ngIf="form.controls['Nombre'].hasError('required')"
            >Este campo es <strong>requerido</strong>.</mat-error
          >
          <mat-error *ngIf="form.controls['Nombre'].hasError('maxlength')"
            >Supero el largo maximo.</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6" *ngIf="local_data.action != 'Eliminar'">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Correo</mat-label>
          <input
            type="email"
            placeholder="Correo"
            formControlName="Correo"
            matInput
            [disabled]="local_data.action == 'Ver'"
          />
          <mat-error *ngIf="form.controls['Correo'].hasError('email')"
            >Formato invalido.</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-6" *ngIf="local_data.action != 'Eliminar'">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Telefono</mat-label>
          <input
            type="tel"
            placeholder="Telefono"
            formControlName="Telefono"
            matInput
            [disabled]="local_data.action == 'Ver'"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12" *ngIf="local_data.action != 'Eliminar'">
        <app-buscar-localidad
          [localidad]="local_data.localidad"
          (eventoLocalidad)="seleccionaLocalidad($event)"
        ></app-buscar-localidad>
      </div>
    </div>
    <div class="row">
      <div class="col" *ngIf="local_data.action != 'Eliminar'">
        <mat-form-field
          appearance="outline"
          class="example-full-width col-lg-12"
        >
          <mat-label>Descripción</mat-label>
          <textarea
            matInput
            #message
            name="Descripcion"
            formControlName="Descripcion"
            [disabled]="local_data.action == 'Ver'"
          ></textarea>
          <mat-error *ngIf="form.controls['Descripcion'].hasError('maxlength')"
            >Supero el largo maximo.</mat-error
          >
        </mat-form-field>
      </div>
    </div>
  </form>
  <div *ngIf="local_data.action == 'Eliminar'">
    ¿Esta seguro que desea eliminar el registro
    <b>{{ local_data.descripcion }}</b
    >?
  </div>
</div>
<br />
<mat-divider></mat-divider>
<div mat-dialog-actions align="end">
  <button
    mat-raised-button
    *ngIf="local_data.action != 'Eliminar'"
    color="primary"
    (click)="doAction()"
    [disabled]="!form.valid || localidad == undefined"
  >
    {{ local_data.action }}
  </button>
  <button
    mat-raised-button
    *ngIf="local_data.action == 'Eliminar'"
    color="warn"
    (click)="doAction()"
  >
    {{ local_data.action }}
  </button>
  <button mat-button (click)="closeDialog()" color="warn"><span>Cancelar</span></button>
</div>
