import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginRequest } from '@models/login/login-model.model copy';
import { JwtPayload, TokenModel } from '@models/login/token-model.model';
import { RolDto } from '@models/seguridad/roles/rolDto.model';
import { UsuarioDto } from '@models/usuario/usuarioDto.model';
import { isFalsy } from 'utility-types';
import { UtilidadesController } from 'src/app/controllers/utilidades.controller';
import { Router } from '@angular/router';
import { BaseDto } from '@models/base/baseDto.model';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private tokenKey: string = "token"
  private expirationTokenKey: string = "expiration-token"
  private currentUser: string = "current-user"
  private id!: number;


  set setId(id: number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem(this.tokenKey)

    if (!token)
      return false

    const expiration = localStorage.getItem(this.expirationTokenKey)

    if (isFalsy(expiration)) {
      return false
    }
    else {
      const expirationDate = new Date(expiration)
      if (expirationDate <= new Date()) {
        this.logout()
        return false
      }
    }

    return true
  }

  login(loginRequest: LoginRequest): Observable<TokenModel> {
    return this.http.post<TokenModel>('/api/Auth/Login', loginRequest)
  }

  getAllRoll(): Observable<RolDto[]> {
    return this.http.get<RolDto[]>('/api/Rol/GetAll')
  }

  setToken(tokenModel: TokenModel) {
    const fechaHoy = new Date
    const fechaExpira = new Date(fechaHoy.getFullYear(), fechaHoy.getMonth(), fechaHoy.getDate(), fechaHoy.getHours(), fechaHoy.getMinutes() + tokenModel.expires_In)
    localStorage.setItem(this.tokenKey, tokenModel.auth_Token)
    localStorage.setItem(this.expirationTokenKey, fechaExpira.toString())
  }

  setCurrentUser(usuarioDto: UsuarioDto) {
    let userString = JSON.stringify(usuarioDto).toString()
    let userEncrypt =  UtilidadesController.encrypt(userString)
    let userBase64 = btoa(userEncrypt.toString())
    localStorage.setItem(this.currentUser, userBase64)
  }

  getCurrentUser() {
    let currentUser: UsuarioDto = new UsuarioDto
    let userSession = localStorage.getItem(this.currentUser);
    if (!isFalsy(userSession)) {
      let userBase64 = atob(userSession)
      let userDescript =  UtilidadesController.decrypt(userBase64)
      currentUser = JSON.parse(userDescript) as UsuarioDto
    }

    if (isFalsy(currentUser)) {
      this.logout()
    }
    return currentUser
  }

  getCurrentEmpresa() {
    let currentUser: UsuarioDto = new UsuarioDto
    let userSession = localStorage.getItem(this.currentUser);
    if (!isFalsy(userSession)) {
      let userBase64 = atob(userSession)
      let userDescript =  UtilidadesController.decrypt(userBase64)
      currentUser = JSON.parse(userDescript) as UsuarioDto
    }

    if (isFalsy(currentUser)) {
      this.logout()
    }
    return currentUser.empresa.id
  }


  logout() {
    localStorage.removeItem(this.tokenKey)
    localStorage.removeItem(this.expirationTokenKey)
    localStorage.removeItem(this.currentUser)
    localStorage.removeItem("parametroSistemaValues")
    this.router.navigate(["/login"]);
  }

  getFieldToken() {
    const token = localStorage.getItem(this.tokenKey)
    let dataToken = new JwtPayload
    if (!token) {
      this.logout()
    }
    else {
      dataToken = JSON.parse(atob(token.split('.')[1])) as JwtPayload
    }
    return dataToken
  }

  getToken() {
    const token = localStorage.getItem(this.tokenKey)
    return token
  }

  getUserSession(): JwtPayload {
    const user = this.getFieldToken()
    return user
  }


  public getIdUser(): number {
    const user = this.getFieldToken()
    return parseInt(user.nameid)
  }

  getUserNameSesion(): string {
    const user = this.getFieldToken()
    return user.unique_name
  }

  getIdUserRol(): number {
    const user = this.getFieldToken()
    return parseInt(user.role)
  }
}
