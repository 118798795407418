<mat-dialog-content *appRole="['Audiencias.Crear']">
  <ngx-spinner
    [zIndex]="100"
    name="spAudiencia"
    bdColor="rgba(255,255,255,0.8)"
    size="default"
    color=" #009688"
    type="ball-atom"
    [fullScreen]="false"
  >
  </ngx-spinner>
  <form [formGroup]="audienciaForm">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Sala</mat-label>
          <input
            matInput
            type="text"
            maxlength="200"
            style="text-transform: uppercase"
            formControlName="Sala"
          />
          <mat-error
            *ngIf="audienciaForm.controls['Sala'].hasError('maxlength')"
            >Supero el largo maximo.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Lugar</mat-label>
          <input
            matInput
            type="text"
            maxlength="200"
            style="text-transform: uppercase"
            formControlName="Lugar"
          />
          <mat-error
            *ngIf="audienciaForm.controls['Lugar'].hasError('required')"
            >Este campo es <strong>requerido</strong>.</mat-error
          >
          <mat-error
            *ngIf="audienciaForm.controls['Lugar'].hasError('maxlength')"
            >Supero el largo maximo.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Fecha</mat-label>
          <input
            matInput
            type="datetime-local"
            formControlName="Fecha"
            placeholder="MM/DD/YYYY"
          />
          <mat-error
            *ngIf="audienciaForm.controls['Fecha'].hasError('required')"
            >Este campo es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <ngx-spinner
            [zIndex]="-1"
            name="spTiposAudiencia"
            bdColor="rgba(255,255,255,0.8)"
            size="small"
            color=" #009688"
            type="line-scale"
            [fullScreen]="false"
          ></ngx-spinner>
          <mat-label>Tipo Audiencia</mat-label>
          <mat-select
            placeholder="Seleccione opcion"
            formControlName="TiposAudiencia"
          >
            <mat-option
              [value]="tipoAudiencia.id"
              *ngFor="
                let tipoAudiencia of tiposAudiencia;
                let i = index;
                trackBy: trackByItems
              "
            >
              {{ tipoAudiencia.descripcion }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              audienciaForm.controls['TiposAudiencia'].hasError('required')
            "
            >Este campo es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-buscar-persona
          [loadBar]="false"
          [nombre]="'Fiscal'"
          (eventEntity)="selectFiscal($event)"
        >
        </app-buscar-persona>
      </div>
      <div class="col">
        <app-buscar-persona
          [loadBar]="false"
          [nombre]="'Juez'"
          (eventEntity)="selectJuez($event)"
        >
        </app-buscar-persona>
      </div>
      <div class="col">
        <app-buscar-persona
          [loadBar]="false"
          [nombre]="'Defensa'"
          (eventEntity)="selectDefensa($event)"
        >
        </app-buscar-persona>
      </div>
    </div>

    <div class="row">
      <mat-form-field appearance="outline" class="example-full-width col-lg-12">
        <mat-label>Descripción</mat-label>
        <textarea
          matInput
          #message
          name="Descripcion"
          formControlName="Descripcion"
        ></textarea>
        <mat-hint align="start">Amplie información</mat-hint>
        <mat-hint align="end">{{ message.value.length }} / 2000</mat-hint>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="primary"
    style="margin-right: 3px"
    name="Agregar"
    [disabled]="!audienciaForm.valid || data.idExpediente == undefined"
    (click)="onSubmit()"
  >
  <span>Agregar</span>
  </button>
</mat-dialog-actions>
