<div *appRole="['Documentos.Crear']">
  <ngx-spinner
    name="docExistente"
    bdColor="rgba(255,255,255,0.8)"
    size="default"
    color=" #009688"
    type="ball-atom"
    [fullScreen]="false"
  >
  </ngx-spinner>
  <form [formGroup]="formDocuementos">
    <div class="row">
      <div class="col-lg-3">
        <ngx-spinner
          name="spBusquedaAsunto"
          bdColor="rgba(255,255,255,0.8)"
          size="small"
          color=" #009688"
          type="line-scale"
          [fullScreen]="false"
        ></ngx-spinner>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Asunto</mat-label>
          <mat-select
            placeholder="Seleccione opcion"
            formControlName="Asuntos"
            (selectionChange)="selectAsunto($event)"
          >
            <mat-option
              [value]="asunto.id"
              *ngFor="
                let asunto of lstAsuntos;
                let i = index;
                trackBy: trackByItems
              "
            >
              {{ asunto.descripcion }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="idAsunto != 0">
        <mat-form-field appearance="outline" class="full-width">
          <ngx-spinner
            name="spBusquedaDocumento "
            bdColor="rgba(255,255,255,0.8) "
            size="small"
            color=" #009688 "
            type="line-scale "
            [fullScreen]="false"
          ></ngx-spinner>
          <mat-label>Documentos</mat-label>
          <mat-select
            placeholder="Seleccione opcion "
            formControlName="Documentos "
            (selectionChange)="selectDocumento($event)"
          >
            <mat-option
              [value]="documentos.id"
              *ngFor="
                let documentos of lstDocumentos;
                let i = index;
                trackBy: trackByItems
              "
            >
              {{ documentos.descripcion }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.Documentos.errors?.required"
            >Este campo es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-lg-3">
        <button
          mat-raised-button
          (click)="asociarDocumento()"
          [disabled]="!formDocuementos || idDocumento === 0"
          color="primary "
        >
          <mat-icon>post_add</mat-icon> Asociar documento
        </button>
      </div>
    </div>
  </form>
</div>
