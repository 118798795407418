<!-- <button mat-icon-button class="close-button" [mat-dialog-close]="true">
<mat-icon class="close-icon" color="warn">close</mat-icon>
</button> -->
<h1 mat-dialog-t itle>Enviar correo</h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <ngx-spinner
    [zIndex]="100"
    bdColor="rgba(255,255,255,0.8)"
    size="default"
    color=" #009688"
    type="ball-atom"
    [fullScreen]="false"
  ></ngx-spinner>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-5">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Asunto</mat-label>
          <input
            cdkFocusInitial
            formControlName="Asunto"
            matInput
            formControlName="Asunto"
          />
          <!-- <mat-error *ngIf="f.Asunto.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error> -->
        </mat-form-field>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-lg-10">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Destinatarios</mat-label>
          <!-- <mat-chip-list #chipList aria-label="Destinatarios" multiple formControlName="Destinatarios">
                        <mat-chip *ngFor="let destinatario of destinatarios;let i=index;trackBy:trackByItems" [selected]="destinatario" [value]="destinatario" (removed)="removeKeyword(destinatario)">
                            {{destinatario}}
                        </mat-chip>
                        <input placeholder="Nuevo correo..." [matChipInputFor]="chipList" (matChipInputTokenEnd)="addKeywordFromInput($event)">
                    </mat-chip-list> -->
          <!-- <mat-error *ngIf="f.Destinatarios.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error> -->
        </mat-form-field>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-lg-10">
        <ngx-editor-menu [editor]="editorBody"></ngx-editor-menu>
        <ngx-editor
          [editor]="editorBody"
          formControlName="Body"
          [disabled]="false"
          [placeholder]="'Escriba aqui...'"
        >
        </ngx-editor>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-lg-10">
        <app-file-upload-multiple
          (eventoArchivo)="archivosCargados($event)"
        ></app-file-upload-multiple>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <ng-template #elseTemplate>
    <p>
      ¿Esta seguro que desea eliminar el registro
      <b>{{ local_data.descripcion }}</b
      >?
    </p> </ng-template
  ><br />
</div>
<mat-divider></mat-divider>
<br />
<div mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="primary"
    (click)="sendEmail()"
    [disabled]="!form.valid"
  >
    <mat-icon matSuffix>send </mat-icon> <span>Enviar</span>
  </button>
  <button mat-button color="warn"><span>Cancelar</span></button>
</div>
