<div>
  <app-go-back-and-title titulo="Modificar Expediente"></app-go-back-and-title>

  <ngx-spinner [zIndex]="100" name="spExpediente" bdColor="rgba(255,255,255,0.8)" size="default" color=" #009688"
    type="ball-atom" [fullScreen]="false"></ngx-spinner>

  <form [formGroup]="expedienteForm" *appRole="['Expedientes.Modificar']">


    <mat-card style="margin-bottom: 20px">
      <mat-card-header>
        <mat-card-title>Caso</mat-card-title>
        <mat-card-subtitle>Información sobre el caso</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <span><strong>Caratula caso:</strong></span>
            {{
            expedienteDto.caso == undefined
            ? ""
            : (expedienteDto.caso.caratula | uppercase)
            }}
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <span><strong>Fecha Comienzo:</strong></span>
            {{
            expedienteDto.caso == undefined
            ? ""
            : (expedienteDto.caso.fechaComienzo | date : "short")
            }}
          </div>
          <div class="col-3">
            <span><strong> Fecha Finalizacion:</strong></span>
            {{
            expedienteDto.caso == undefined
            ? ""
            : (expedienteDto.caso.fechaFinalizacion | date : "short")
            }}
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- PRIMER CARD -->
    <mat-card style="margin-bottom: 20px">
      <mat-card-header>
        <mat-card-title>Caratula expediente</mat-card-title>
        <mat-card-subtitle>Información sobre la caratula del expediente.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <!-- Fila Caratula / Organismo -->
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Carátula</mat-label>
              <input matInput type="text" maxlength="200" style="text-transform: uppercase"
                formControlName="Caratula" />
              <mat-error *ngIf="expedienteForm.controls['Caratula'].hasError('required')">
                Este campo es <strong>requerido</strong>.
              </mat-error>
              <mat-error *ngIf="
                  expedienteForm.controls['Caratula'].hasError('maxlength')
                ">
                Supero el largo maximo.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <ngx-spinner [zIndex]="-1" name="spOrganismos" bdColor="rgba(255,255,255,0.8)" size="small"
                color=" #009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
              <mat-label>Organismo</mat-label>
              <mat-select formControlName="Organismo">
                <mat-option [value]="organismo.id" *ngFor="let organismo of organismos; trackBy: trackByItems">
                  {{ organismo.nombre }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  expedienteForm.controls['Organismo'].hasError('required')
                ">
                Supero el largo maximo.
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Fila CUIJ / Demanda / Demandado / Causa -->
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>CUIJ</mat-label>
              <input matInput type="text" mask="00-00000000-0" formControlName="Cuij" />
              <mat-error *ngIf="expedienteForm.controls['Cuij'].hasError('required')">
                Este campo es <strong>requerido</strong>.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Demanda</mat-label>
              <input matInput type="text" maxlength="200" style="text-transform: uppercase" formControlName="Demanda" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Demandado</mat-label>
              <input matInput type="text" maxlength="200" style="text-transform: uppercase"
                formControlName="Demandado" />
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Causa</mat-label>
              <input matInput type="text" maxlength="200" style="text-transform: uppercase" formControlName="Causa" />
            </mat-form-field>
          </div>
        </div>

        <!-- Fila Número / Sufijo / Bis -->
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Número</mat-label>
              <input matInput type="text" maxlength="200" style="text-transform: uppercase" formControlName="Numero" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Sufijo</mat-label>
              <input matInput type="text" maxlength="200" style="text-transform: uppercase" formControlName="Sufijo" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Bis</mat-label>
              <input matInput type="text" maxlength="200" style="text-transform: uppercase" formControlName="Bis" />
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- SEGUNDO CARD -->
    <mat-card style="margin-bottom: 20px">
      <mat-card-header>
        <mat-card-title>Gestion de expediente</mat-card-title>
        <mat-card-subtitle>Información complementaria al expediente.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <!-- Fila Ubicación / Fecha Ingreso MEU / Clave SISFE / Monto Demanda -->
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Ubicación</mat-label>
              <input matInput type="text" maxlength="200" style="text-transform: capitalize"
                formControlName="Ubicacion" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Fecha Ingreso MEU</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="FechaIngresoMEU" />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Clave SISFE</mat-label>
              <input matInput type="text" formControlName="ClaveSisfeExpediente" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Monto Demanda</mat-label>
              <input type="text" currencyMask placeholder="Monto Demanda" formControlName="MontoDemanda" matInput />
            </mat-form-field>
          </div>
        </div>
        <!-- Fila Estado Expediente Perito / Estado Expediente / Tipo Expediente / Estado Procesal -->
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <ngx-spinner [zIndex]="-1" name="spEstadosExpedientePerito" bdColor="rgba(255,255,255,0.8)" size="small"
                color=" #009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
              <mat-label>Estado Expediente Perito</mat-label>
              <mat-select formControlName="EstadoExpedientesPerito">
                <mat-option [value]="estadoExpedientesPerito.id" *ngFor="
                    let estadoExpedientesPerito of estadosExpedientesPerito;
                    trackBy: trackByItems
                  ">
                  {{ estadoExpedientesPerito.descripcion }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <ngx-spinner [zIndex]="-1" name="spEstadosExpediente" bdColor="rgba(255,255,255,0.8)" size="small"
                color=" #009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
              <mat-label>Estado Expediente</mat-label>
              <mat-select formControlName="EstadoExpedientes">
                <mat-option [value]="estadoExpediente.id" *ngFor="
                    let estadoExpediente of estadosExpedientes;
                    trackBy: trackByItems
                  ">
                  {{ estadoExpediente.descripcion }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <ngx-spinner [zIndex]="-1" name="spTiposExpedientes" bdColor="rgba(255,255,255,0.8)" size="small"
                color=" #009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
              <mat-label>Tipo Expediente</mat-label>
              <mat-select formControlName="TipoExpediente">
                <mat-option [value]="tipoExpediente.id" *ngFor="
                    let tipoExpediente of tiposExpedientes;
                    trackBy: trackByItems
                  ">
                  {{ tipoExpediente.descripcion }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  expedienteForm.controls['TipoExpediente'].hasError('required')
                ">
                Este campo es <strong>requerido</strong>.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <ngx-spinner [zIndex]="-1" name="spEstadosProcesal" bdColor="rgba(255,255,255,0.8)" size="small"
                color=" #009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
              <mat-label>Estado Procesal</mat-label>
              <mat-select formControlName="EstadoProcesal">
                <mat-option [value]="estadoProcesal.id" *ngFor="
                    let estadoProcesal of estadosProcesal;
                    trackBy: trackByItems
                  ">
                  {{ estadoProcesal.descripcion }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  expedienteForm.controls['EstadoProcesal'].hasError('required')
                ">
                Este campo es <strong>requerido</strong>.
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Fila Descripción (full-width) -->
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Descripción</mat-label>
              <textarea matInput #message name="Descripcion" formControlName="Descripcion"></textarea>
              <mat-error *ngIf="
                  expedienteForm.controls['Descripcion'].hasError('maxlength')
                ">
                Este campo es <strong>requerido</strong>.
              </mat-error>
              <mat-hint align="start">Amplie información</mat-hint>
              <mat-hint align="end">{{ message.value.length }} / 2000</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <app-button-save [disabled]="!expedienteForm.valid" (click)="onSubmit()"></app-button-save>
  </form>
</div>
