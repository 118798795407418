import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SharedModule } from '@modules/shared.module';

@Component({
  selector: 'app-form-caso',
  standalone: true,
  imports: [SharedModule],
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./form-caso.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCasoComponent { }
