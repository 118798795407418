import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '@modules/shared.module';

@Component({
  selector: 'app-button-edit',
  standalone: true,
  imports: [SharedModule],
  template: `<button mat-raised-button color="primary" [disabled]="disabled" name="Modificar">
  <mat-icon>edit</mat-icon> Modificar
</button>`,
  styleUrls: ['./button-edit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonEditComponent {
  @Input() disabled : boolean = false
 }
