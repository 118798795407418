<mat-card *appRole="['Expedientes.Ver']">
  <!-- Cabecera -->
  <mat-card-header>
    <app-button-new [routerLink]="['/expedientes/create']" #tooltip="matTooltip"
      matTooltip="Crear expediente."></app-button-new>
  </mat-card-header>

  <!-- Contenido de filtros -->
  <mat-card-content>
    <form [formGroup]="formFilter">
      <div class="row">
        <div class="col-sm-12 col-md-8 col-lg-5">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Caratula</mat-label>
            <input matInput type="text" maxlength="200" style="text-transform: uppercase" formControlName="Caratula" />
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Cuij</mat-label>
            <input matInput type="text" maxlength="200" style="text-transform: uppercase" formControlName="Cuij" />
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-2">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Número</mat-label>
            <input matInput type="text" maxlength="200" style="text-transform: uppercase" formControlName="Numero" />
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-2">
          <mat-checkbox formControlName="ClaveSisfe">Sin Clave</mat-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="checkedFiltroAvanzado">
          <mat-form-field appearance="outline" class="w-100">
            <ngx-spinner [zIndex]="-1" name="spBusquedaEstadoExpedientePerito" bdColor="rgba(255,255,255,0.8)"
              size="small" color=" #009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
            <mat-label>Estado Expediente Perito</mat-label>
            <mat-select placeholder="Seleccione opcion" multiple formControlName="EstadoExpedientePerito">
              <mat-option [value]="estadoExpedientesPerito.id" *ngFor="
                  let estadoExpedientesPerito of estadosExpedientePerito;
                  let i = index;
                  trackBy: trackByItems
                ">
                {{ estadoExpedientesPerito.descripcion }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="checkedFiltroAvanzado">
          <mat-form-field appearance="outline" class="w-100">
            <ngx-spinner [zIndex]="-1" name="spBusquedaEstadoExpediente" bdColor="rgba(255,255,255,0.8)" size="small"
              color=" #009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
            <mat-label>Estado Expediente</mat-label>
            <mat-select placeholder="Seleccione opcion" multiple formControlName="EstadoExpediente">
              <mat-option [value]="estadoExpediente.id" *ngFor="
                  let estadoExpediente of estadosExpediente;
                  let i = index;
                  trackBy: trackByItems
                ">
                {{ estadoExpediente.descripcion }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-2" *ngIf="checkedFiltroAvanzado">
          <mat-form-field appearance="outline" class="w-100">
            <ngx-spinner [zIndex]="-1" name="spBusquedaTipoExpediente" bdColor="rgba(255,255,255,0.8)" size="small"
              color=" #009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
            <mat-label>Tipo Expediente </mat-label>
            <mat-select placeholder="Seleccione opcion" formControlName="TipoExpediente">
              <mat-option [value]="tipoExpediente.id" *ngFor="
                  let tipoExpediente of tiposExpediente;
                  let i = index;
                  trackBy: trackByItems
                ">
                {{ tipoExpediente.descripcion }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-2" *ngIf="checkedFiltroAvanzado">
          <mat-form-field appearance="outline" class="w-100">
            <ngx-spinner [zIndex]="-1" name="spBusquedaOrganismos" bdColor="rgba(255,255,255,0.8)" size="small"
              color=" #009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
            <mat-label>Organismo </mat-label>
            <mat-select placeholder="Seleccione opcion" formControlName="Organismo">
              <mat-option [value]="organismo.id" *ngFor="
                  let organismo of organismos;
                  let i = index;
                  trackBy: trackByItems
                ">
                {{ organismo.nombre }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-auto">
          <app-button-filter-table (click)="filtrar()"></app-button-filter-table>
        </div>
        <div class="col-auto">
          <mat-slide-toggle (change)="filtrosAvanzados($event)" color="primary">
            Filtros Avanzados
          </mat-slide-toggle>
        </div>
      </div>
    </form>
  </mat-card-content>

  <mat-card-content>
    <ngx-spinner [zIndex]="100" name="spExpediente" bdColor="rgba(255,255,255,0.8)" size="default" color=" #009688"
      type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <br>
    <mat-divider></mat-divider>
    <br>
    <!-- Barra de acciones -->
    <div class="row align-items-center">

      <div class="col-auto d-flex align-items-center">
        <app-button-action *ngIf="data"
          [actions]="[
      { label: 'Solicitar clave', icon: 'key', handler: solicitarClavePorCorreo, roles: ['Expedientes.Modificar'] }]"></app-button-action>

      </div>
      <div class="col-auto d-flex align-items-center">
        <app-button-export-file *ngIf="data" (click)="exportarTabla()"></app-button-export-file>
      </div>
      <!-- Espaciado intermedio -->
      <div class="col"></div>

      <!-- Campo de búsqueda alineado a la derecha -->
      <div class="col-lg-4 col-sm-12 text-end">
        <mat-form-field appearance="outline" *ngIf="data" class="w-100 mb-0">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Buscar</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input />
        </mat-form-field>
      </div>
    </div>

    <!-- Tabla De datos -->
    <div>

      <table class="mat-elevation-z8" mat-table [dataSource]="dataSource" *ngIf="data" matSort matSortStart="asc"
        matTableExporter [dataSource]="dataSource" #exporter="matTableExporter">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <mat-header-cell mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let expediente">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(expediente) : null"
              [checked]="selection.isSelected(expediente)" [aria-label]="checkboxLabel(expediente)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="caratula">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
            <strong>Caratula</strong>
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let expediente">
            <span>{{ expediente.caratula | uppercase }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="cuij">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
            <strong>Cuij</strong>
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let expediente">
            <span><a [routerLink]="['/expedientes/', expediente.id]">{{
                expediente.cuij | uppercase
                }}</a></span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="estadoExpediente">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Estado Exte.</strong>
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let expediente">
            <span>{{
              expediente.estadoExpediente?.descripcion | uppercase
              }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="estadoExpedientePerito">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
            <strong>Estado Exte. Perito</strong>
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let expediente">
            <span>{{
              expediente.estadoExpedientePerito == undefined
              ? ""
              : (expediente.estadoExpedientePerito.descripcion | uppercase)
              }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="clave">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Clave</strong>
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let expediente">
            <span>{{
              expediente.claveSisfeExpediente == undefined
              ? ""
              : expediente.claveSisfeExpediente
              }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="fechaModificacion">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Fecha Modificación</strong>
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let expediente">
            <span>{{
              expediente.fechaModificacion == undefined
              ? ""
              : (expediente.fechaModificacion | date : "dd/MM/yyyy")
              }}</span>
          </mat-cell>
        </ng-container>

        <!-- Accion Column -->
        <ng-container matColumnDef="accion">
          <mat-header-cell mat-header-cell *matHeaderCellDef>
            <strong>Acción</strong>
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let expediente" class="action-link">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="['/expedientes/', expediente.id]" *appRole="['Expedientes.Ver']">
                <mat-icon>visibility</mat-icon>
                <span>Ver</span>
              </button>
              <button mat-menu-item [routerLink]="['/expedientes/edit/', expediente.id]"
                *appRole="['Expedientes.Modificar']">
                <mat-icon>edit</mat-icon>
                <span>Editar</span>
              </button>
              <button mat-menu-item (click)="openDialog('Eliminar', expediente)" *appRole="['Expedientes.Eliminar']">
                <mat-icon>delete</mat-icon>
                <span>Eliminar</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row matRipple *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></mat-row>
      </table>

      <app-sin-datos-para-mostrar [dataSource]="dataSource"></app-sin-datos-para-mostrar>

      <mat-paginator showFirstLastButtons class="paginator" *ngIf="data" [pageSize]="20"
        [pageSizeOptions]="[5, 10, 20, 50, 100, 200]">
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
