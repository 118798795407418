import { NgModule } from "@angular/core";
import { ButtonActionComponent } from "../component/button-action/button-action.component";
import { ButtonExportFileComponent } from "../component/button-export-file/button-export-file.component";
import { ButtonFilterTableComponent } from "../component/button-filter-table/button-filter-table.component";
import { ButtonSaveComponent } from "../component/button-save/button-save.component";
import { ButtonCloseDialogComponent } from "../component/button-close-dialog/button-close-dialog.component";
import { ButtonDeleteComponent } from "../component/button-delete/button-delete.component";
import { ButtonCancelComponent } from "../component/button-cancel/button-cancel.component";
import { ButtonCloseComponent } from "../component/button-close/button-close.component";
import { DialogCrudReadComponent } from "../component/dialog-crud-read/dialog-crud-read.component";
import { ButtonAddComponent } from "../component/button-add/button-add.component";
import { ButtonGobackComponent } from "../component/button-goback/button-goback.component";
import { ButtonEditComponent } from "../component/button-edit/button-edit.component";
import { ButtonNewComponent } from "../component/button-new/button-new.component";
import { GoBackAndTitleComponent } from "../component/go-back-and-title/go-back-and-title.component";

@NgModule({
    imports: [
      ButtonActionComponent,
      ButtonExportFileComponent,
      ButtonFilterTableComponent,
      ButtonSaveComponent,
      ButtonCloseDialogComponent,
      ButtonDeleteComponent,
      ButtonCancelComponent,
      ButtonCloseComponent,
      DialogCrudReadComponent,
      ButtonAddComponent,
      ButtonGobackComponent,
      ButtonEditComponent,
      ButtonNewComponent,
      GoBackAndTitleComponent
    ],
    declarations: [

    ],

    providers: [
    ],
    exports: [
      ButtonActionComponent,
      ButtonExportFileComponent,
      ButtonFilterTableComponent,
      ButtonSaveComponent,
      ButtonCloseDialogComponent,
      ButtonDeleteComponent,
      ButtonCancelComponent,
      ButtonCloseComponent,
      DialogCrudReadComponent,
      ButtonAddComponent,
      ButtonGobackComponent,
      ButtonEditComponent,
      ButtonNewComponent,
      GoBackAndTitleComponent
     ]
})
export class ComponentModule { }
