import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedModule } from '@modules/shared.module';

@Component({
  selector: 'app-button-export-file',
  templateUrl: './button-export-file.component.html',
  styleUrls: ['./button-export-file.component.css'],
  standalone:  true,
  imports:[SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ButtonExportFileComponent {
  @Input() actions: { label: string; icon: string; handler: () => void; roles: string[] }[] = [];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // Registra el ícono con un nombre único
    this.matIconRegistry.addSvgIcon(
      'file_export', // Nombre del ícono
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/file_export_24dp_009788_FILL0_wght400_GRAD0_opsz24.svg')
    );
  }
  ngOnInit(): void {
  }
}
