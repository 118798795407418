import { BaseDto } from "@models/base/baseDto.model";
import { EmpresaDto } from "@models/empresa/empresaDto.model";

export class CasoDto extends BaseDto
{
  public caratula!: string
  public activo!: boolean
  public fechaComienzo!: Date
  public fechaFinalizacion!: Date
  public empresa!: EmpresaDto
  public tipoCaso!: BaseDto
  public estadoCaso!: BaseDto

}
