import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavSearchPerfilLogoComponent } from '../views/nav-search-perfil-logo/nav-search-perfil-logo.component';
import { SharedModule } from 'src/app/modules/shared.module';
import { MenuItemComponent } from '../views/menu-item/menu-item.component';
import { MenuSettingComponent } from '../views/menu-setting/menu-setting.component';
import { ConfiguracionModule } from './configuracion.module';
import { NotificacionModule } from './notificacion.module';
import { ApiVersionModule } from './api-version.module';
import { MainToolbarComponent } from '@views/main-toolbar/main-toolbar.component';
import { MenuComponent } from 'ngx-editor';
import { MenuPrincipalComponent } from '@views/menu/menu.component';

@NgModule({
  declarations: [
    NavSearchPerfilLogoComponent,
    MenuItemComponent,
    MenuSettingComponent,
    MenuPrincipalComponent,
  ],
  imports: [
    CommonModule,
    ConfiguracionModule,
    SharedModule,
    NotificacionModule,
    ApiVersionModule,
  ],
  exports: [
    NavSearchPerfilLogoComponent,
    MenuItemComponent,
    MenuSettingComponent,
    MenuPrincipalComponent,
  ],
})
export class NavSearchPerfilLogoModule {}
