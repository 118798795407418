import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-buscar-general',
  templateUrl: './buscar-general.component.html',
  styleUrls: ['./buscar-general.component.css'],
})
export class BuscarGeneralComponent {
  @Output() search = new EventEmitter<string>();
  searchQuery: string = '';

  constructor(private router: Router) {}

  onSearch() {
    if (this.searchQuery.trim()) {
      this.router.navigate(['/resultados-busqueda'], { queryParams: { query: this.searchQuery } });
    }
  }
}
