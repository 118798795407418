<form [formGroup]="loginForm" (ngSubmit)="loguearse()">
  <div class="form-group">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Usuario</mat-label>
      <input type="text" maxlength="50" matInput placeholder="Ingrese usuario" formControlName="Usuario">
      <!-- <mat-error *ngIf="f.Usuario.errors?.required">Ingrese nombre de usuario.</mat-error>
      <mat-error *ngIf="f.Usuario.errors?.maxlength">Superó el máximo de caracteres.</mat-error> -->
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Contraseña</mat-label>
      <input type="password" maxlength="50" matInput placeholder="Ingrese contraseña" formControlName="Clave">
      <!-- <mat-error *ngIf="f.Clave.errors?.required">Ingrese una contraseña.</mat-error>
      <mat-error *ngIf="f.Clave.errors?.maxlength">Superó el máximo de caracteres.</mat-error> -->
    </mat-form-field>
  </div>

  <div class="form-group text-center">
    <button mat-raised-button type="submit" [disabled]="!loginForm.valid" color="primary" class="btn-submit">
      <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Ingresar
    </button>
  </div>
</form>
