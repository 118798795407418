<h2>Modificar nota</h2>
<mat-dialog-content>
  <ngx-spinner
    [zIndex]="100"
    name="spAudiencia"
    bdColor="rgba(255,255,255,0.8)"
    size="default"
    color=" #009688"
    type="ball-atom"
    [fullScreen]="false"
  >
  </ngx-spinner>
  <form [formGroup]="form">
    <div class="row">
      <mat-form-field appearance="outline" class="example-full-width col-lg-12">
        <mat-label>Descripción</mat-label>
        <textarea
          matInput
          #message
          name="Descripcion"
          formControlName="Descripcion"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="primary"
    style="margin-right: 3px"
    name="Guardar"
    [disabled]="!form.valid || data.idExpediente == undefined"
    (click)="onSubmit()"
  >
    <span>Guardar</span>
  </button>
</mat-dialog-actions>
