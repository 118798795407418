import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateOrganismoParteRequest } from '@models/organismoParte/createOrganismoParteRequest.model';
import { OrganismoParteDto } from '@models/organismoParte/organismoParteDto.model';
import { PersonaDto } from '@models/persona/personaDto.model';
import { OrganismoParteService } from '@services/organismoParte/organismo-parte.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoParteService } from '@services/tipoParte/tipoParte.service';
import { DialogDeleteComponent } from 'src/app/component/dialog-delete/dialog-delete.component';
import { DialogCreatePersonaSimpleComponent } from '@views/personas/dialog-create-persona-simple/dialog-create-persona-simple.component';
import { DialogViewBasicPersonaComponent } from '@views/personas/dialog-view-basic-persona/dialog-view-basic-persona.component';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-form-organismo-partes',
  templateUrl: './form-organismo-partes.component.html',
  styleUrls: ['./form-organismo-partes.component.css']
})
export class FormOrganismoPartesComponent {
  formBuilder: FormBuilder = new FormBuilder;
  OrganismoParteForm: any;
  tiposParte!: BaseDto[];
  OrganismoPartes!: OrganismoParteDto[];
  idOrganismo: any;
  personaDto: any;
  dataSource = new MatTableDataSource<OrganismoParteDto>();
  displayedColumnsOrganismoParte: string[] = ['tipoParte', 'persona', 'descripcion', 'accion'];
  data: boolean = false;
  constructor(
    public dialog: MatDialog,
    private tipoParteService: TipoParteService,
    private organismoParteService: OrganismoParteService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
  ) {

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumnsOrganismoParte = result.matches ?
        ['tipoParte', 'persona', 'accion'] :
        ['tipoParte', 'persona', 'descripcion', 'accion'];
    });


  }

  ngOnInit() {
    let idOrganismo = this.activatedRoute.snapshot.params['id']
    this.idOrganismo = idOrganismo
    this.getOrganismoParteByIdOrganismo(idOrganismo)
    this.getAllTiposParte()

    this.OrganismoParteForm = this.formBuilder.group({
      Descripcion: [''],
      TipoParte: ["", Validators.compose([Validators.required])],
    });
  }

  getOrganismoParteByIdOrganismo(idOrganismo: any) {
    this.spinner.show("spOrganismoParte")
    this.organismoParteService.getAllByIdOrganismo(idOrganismo)
      .subscribe(
        data => {
          this.spinner.hide("spOrganismoParte")
          this.OrganismoPartes = data
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide("spOrganismoParte")
          this.snackBar.showError(error, "Error");
        })
  }

  verInfoPersona(personaDto: PersonaDto) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.autoFocus = false
    dialogConfig.data = personaDto
    dialogConfig.width = "50%"

    const dialogRef = this.dialog.open(DialogViewBasicPersonaComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  onNuevaPersona(): void {
    const dialogRef = this.dialog.open(DialogCreatePersonaSimpleComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      let idPersona = result;
    });
  }
  seleccionPersona(event: any) {
    this.personaDto = event
  }
  getAllTiposParte() {
    this.spinner.show("spTipoParte")
    this.tipoParteService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spTipoParte")
          this.tiposParte = data
        },
        error => {
          this.spinner.hide("spTipoParte")
          this.snackBar.showError(error, "Error");
        })
  }

  onSubmit() {
    if (this.OrganismoParteForm.invalid) {
      return;
    }
    const createOrganismoParteRequest: CreateOrganismoParteRequest = this.setCreateOrganismoParteRequest();
    this.spinner.show("spOrganismoParte")
    this.organismoParteService.create(createOrganismoParteRequest)
      .subscribe(
        data => {
          this.spinner.hide("spOrganismoParte")
          this.snackBar.showSuccess("Se agrego el registro correctamente.", "");
          this.getOrganismoParteByIdOrganismo(this.idOrganismo)
        },
        error => {
          this.spinner.hide("spOrganismoParte")
          this.snackBar.showError(error, "Error");
        })
  }

  setCreateOrganismoParteRequest(): CreateOrganismoParteRequest {

    const createOrganismoParteRequest = new CreateOrganismoParteRequest();

    createOrganismoParteRequest.idTipoParte = this.OrganismoParteForm.controls['TipoParte'].value;
    createOrganismoParteRequest.descripcion = this.OrganismoParteForm.controls['Descripcion'].value;
    createOrganismoParteRequest.idOrganismo = this.idOrganismo
    createOrganismoParteRequest.idPersona = this.personaDto.id

    return createOrganismoParteRequest
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }


  deleteRowData(id: number) {
    this.spinner.show('spOrganismoParte')
    this.organismoParteService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide('spOrganismoParte')
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          this.getOrganismoParteByIdOrganismo(this.idOrganismo);
        },
        error => {
          this.spinner.hide('spOrganismoParte')
          this.snackBar.showError(error, "Error");
        });
  }
}
