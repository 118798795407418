<div class="conteiner">
  <!-- <mat-progress-bar [value]="progressValue" [color]="progressColor">
    </mat-progress-bar> -->
  <div
    *ngIf="online$ | async"
    [@myAnimation]="o.isActivated ? o.activatedRoute : ''"
  >
    <router-outlet #o="outlet"></router-outlet>
  </div>
  <div *ngIf="!(online$ | async)" class="box-check-internet">
    <div class="icon-text">
      <span>Al parecer su equipo se encuentra sin conexion a internet.</span>
    </div>
    <mat-icon class="icono">cloud_off</mat-icon>
  </div>

  <app-my-loader></app-my-loader>
</div>
