<ngx-spinner
  bdColor="rgba(255,255,255,0.8)"
  size="default"
  color=" #009688"
  type="ball-atom"
  [fullScreen]="false"
></ngx-spinner>

<form [formGroup]="passwordForm">
  <div class="row">
    <div class="col-md-3 mb-3">
      <mat-form-field appearance="outline">
        <mat-label>Clave anterior</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          maxlength="50"
          id="ClaveAnterior"
          formControlName="ClaveAnterior"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Ocultar contraseña'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
        <!--<mat-error *ngIf="p.ClaveAnterior.errors?.required">Ingrese clave anterior.
                </mat-error>
                <mat-error *ngIf="p.ClaveAnterior.errors?.maxlength">Supero el maximo de caracteres.
                </mat-error> -->
      </mat-form-field>
    </div>
    <div class="col-md-3 mb-3">
      <mat-form-field appearance="outline">
        <mat-label>Clave nueva</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          maxlength="150"
          id="ClaveNueva"
          formControlName="ClaveNueva"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Ocultar contraseña'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
        <!-- <mat-error *ngIf="p.ClaveNueva.errors?.required">Ingrese clave nueva.
                </mat-error>
                <mat-error *ngIf="p.ClaveNueva.errors?.minlength">Debe superar los 6 caracteres.
                </mat-error>
                <mat-error *ngIf="p.ClaveNueva.errors?.maxlength">Supero el maximo de caracteres.
                </mat-error> -->
      </mat-form-field>
    </div>
    <div class="col-md-3 mb-3">
      <mat-form-field appearance="outline">
        <mat-label>Repetir clave nueva</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          maxlength="150"
          id="ClaveNuevaRepetir"
          formControlName="ClaveNuevaRepetir"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Ocultar contraseña'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
        <!-- <mat-error *ngIf="p.ClaveNuevaRepetir.errors?.required">Repita clave nueva.
                </mat-error>
                <mat-error *ngIf="p.ClaveNuevaRepetir.errors?.maxlength">Supero el maximo de caracteres.
                </mat-error>
                <mat-error *ngIf="p.ClaveNuevaRepetir.errors?.MustMatch">Las claves no coinciden.</mat-error> -->
      </mat-form-field>
    </div>
  </div>
  <div class="col-auto my-1">
    <button
      mat-raised-button
      color="primary"
      [disabled]="!passwordForm.valid"
      (click)="changePassword()"
    >
      Cambiar
    </button>
  </div>
</form>
