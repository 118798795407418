export class CreatePersonaRequest {

  public nombre!: string;
  public apellido!: string;
  public idTipoDocumento!: number | null ;
  public nroDocumento !: string | null ;
  public telefonoPrincipal !: string;
  public telefonoAlternativo !: string | null ;
  public sexo !: string | null ;
  public correo !: string;
  public fechaNacimiento  !: string | null ;
  public ubicacion !: string | null ;
  public idLocalidad !: number | null ;
  public idProvincia !: number | null ;
  public codigoCpa !: string | null ;;
  public idEstadoCivil !: number | null;
  public ocupacion !: string | null ;;
  public activo !: boolean;
  public observacion !: string | null ;;
  public idPais !: number| null  ;
  public telefonoLaboral !: string | null ;
  public ubicacionLaboral !: string | null ;
  public idEmpresa !: number ;
  public idUsuario !: number;

}
