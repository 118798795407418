<div class="search-bar">
  <input
    type="text"
    [(ngModel)]="searchQuery"
    placeholder="Buscar..."
    (keydown.enter)="onSearch()"
  />
  <button mat-icon-button (click)="onSearch()">
    <mat-icon>search</mat-icon>
  </button>
</div>
