import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '@modules/shared.module';
import { ButtonCancelComponent } from '../button-cancel/button-cancel.component';

@Component({
  selector: 'app-button-add',
  standalone: true,
  imports: [SharedModule,ButtonCancelComponent],
  template: `<div class="button-container">
  <app-button-cancel></app-button-cancel>
  <button mat-raised-button color="primary" [disabled]="disabled" name="Agregar">
    <mat-icon>add</mat-icon> Agregar
  </button>
</div>`,
  styleUrls: ['./button-add.component.css'],
})
export class ButtonAddComponent {
  @Input() disabled : boolean = false
 }
