<mat-card *appRole="['Usuarios.Crear']">
  <mat-card-content>
    <ngx-spinner
      bdColor="rgba(255,255,255,0.8)"
      name="spUsuarioCreate"
      size="default"
      color=" #009688"
      type="ball-atom"
      [fullScreen]="false"
    >
    </ngx-spinner>
    <div class="row">
      <div class="col">
        <form [formGroup]="usuarioForm">
          <div class="row">
            <div class="col-lg-3 col-ls-12">
              <mat-form-field appearance="outline">
                <mat-label>Nombre de usuario</mat-label>
                <input
                  matInput
                  type="text"
                  maxlength="50"
                  formControlName="NombreUsuario"
                />
                <mat-error
                  *ngIf="
                    usuarioForm.controls['NombreUsuario'].hasError('required')
                  "
                  >El nombre es
                  <strong>requerido</strong>
                </mat-error>
                <mat-error
                  *ngIf="
                    usuarioForm.controls['NombreUsuario'].hasError('maxlength')
                  "
                  >Supero el maximo de caracteres.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-ls-12">
              <mat-form-field appearance="outline">
                <mat-label>Nombre</mat-label>
                <input
                  matInput
                  type="text"
                  maxlength="50"
                  formControlName="Nombre"
                />
                <mat-error
                  *ngIf="usuarioForm.controls['Nombre'].hasError('required')"
                  >El nombre es
                  <strong>requerido</strong>
                </mat-error>
                <mat-error
                  *ngIf="usuarioForm.controls['Nombre'].hasError('maxlength')"
                  >Supero el maximo de caracteres.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-ls-12">
              <mat-form-field appearance="outline">
                <mat-label>Apellido</mat-label>
                <input
                  matInput
                  type="text"
                  maxlength="50"
                  formControlName="Apellido"
                />
                <mat-error
                  *ngIf="usuarioForm.controls['Apellido'].hasError('required')"
                  >El nombre es
                  <strong>requerido</strong>
                </mat-error>
                <mat-error
                  *ngIf="usuarioForm.controls['Apellido'].hasError('maxlength')"
                  >Supero el maximo de caracteres.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-ls-12">
              <mat-form-field appearance="outline">
                <mat-label>Correo</mat-label>
                <input
                  matInput
                  type="email"
                  maxlength="50"
                  formControlName="Correo"
                />
                <mat-error
                  *ngIf="usuarioForm.controls['Correo'].hasError('required')"
                  >El nombre es
                  <strong>requerido</strong>
                </mat-error>
                <mat-error
                  *ngIf="usuarioForm.controls['Correo'].hasError('maxlength')"
                  >Supero el maximo de caracteres.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-ls-12">
              <mat-form-field appearance="outline">
                <mat-label>Telefono</mat-label>
                <input
                  matInput
                  type="tel"
                  maxlength="50"
                  formControlName="Telefono"
                />
                <mat-error
                  *ngIf="usuarioForm.controls['Telefono'].hasError('required')"
                  >El nombre es
                  <strong>requerido</strong>
                </mat-error>
                <mat-error
                  *ngIf="usuarioForm.controls['Telefono'].hasError('maxlength')"
                  >Supero el maximo de caracteres.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-ls-12">
              <mat-form-field appearance="outline">
                <mat-label>Rol</mat-label>
                <ngx-spinner
                  name="spRol"
                  bdColor="rgba(255,255,255,0.8)"
                  size="small"
                  color=" #009688"
                  type="line-scale"
                  [fullScreen]="false"
                ></ngx-spinner>
                <mat-select
                  required
                  #Sele
                  ctRol
                  placeholder="Seleccione opcion"
                  formControlName="Rol"
                  (selectionChange)="selectRol($event.value)"
                >
                  <mat-option
                    [value]="rol.id"
                    *ngFor="
                      let rol of roles;
                      let i = index;
                      trackBy: trackByItems
                    "
                  >
                    {{ rol.descripcion | titlecase }}</mat-option
                  >
                </mat-select>
                <mat-error
                  *ngIf="usuarioForm.controls['Rol'].hasError('required')"
                  >Este campo es <strong>requerido</strong>.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3 col-ls-12">
              <mat-form-field appearance="outline">
                <mat-label>Clave nueva</mat-label>
                <input
                  matInput
                  [type]="hide ? 'password' : 'text'"
                  maxlength="150"
                  formControlName="ClaveNueva"
                />
                <button
                  mat-icon-button
                  matSuffix
                  (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide"
                >
                  <mat-icon>{{
                    hide ? "visibility_off" : "visibility"
                  }}</mat-icon>
                </button>
                <mat-error
                  *ngIf="
                    usuarioForm.controls['ClaveNueva'].hasError('required')
                  "
                  >Ingrese <strong>clave nueva.</strong>
                </mat-error>
                <mat-error
                  *ngIf="
                    usuarioForm.controls['ClaveNueva'].hasError('minlength')
                  "
                  >Debe superar los 6 caracteres.
                </mat-error>
                <mat-error
                  *ngIf="
                    usuarioForm.controls['ClaveNueva'].hasError('maxlength')
                  "
                  >Supero el maximo de caracteres.
                </mat-error>
                <button
                  matSuffix
                  mat-icon-button
                  color="primary"
                  (click)="generarClaveAleatoria()"
                  aria-label="Example icon button with a home icon"
                  #tooltip="matTooltip"
                  matTooltip="Genera clave aleatoria."
                >
                  <mat-icon>autorenew</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-ls-12">
              <mat-form-field appearance="outline">
                <mat-label>Repetir clave</mat-label>
                <input
                  matInput
                  [type]="hide ? 'password' : 'text'"
                  maxlength="150"
                  formControlName="RepetirClave"
                />
                <button
                  mat-icon-button
                  matSuffix
                  (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide"
                >
                  <mat-icon>{{
                    hide ? "visibility_off" : "visibility"
                  }}</mat-icon>
                </button>
                <mat-error
                  *ngIf="
                    usuarioForm.controls['RepetirClave'].hasError('required')
                  "
                  >Ingrese <strong>clave nueva.</strong>
                </mat-error>
                <mat-error
                  *ngIf="
                    usuarioForm.controls['RepetirClave'].hasError('minlength')
                  "
                  >Debe superar los 6 caracteres.
                </mat-error>
                <mat-error
                  *ngIf="
                    usuarioForm.controls['RepetirClave'].hasError('maxlength')
                  "
                  >Supero el maximo de caracteres.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button
      mat-raised-button
      *appRole="['Usuarios.Crear']"
      color="primary"
      type="submit"
      name="Guardar"
      [disabled]="!usuarioForm.valid"
      (click)="onSubmit()"
      style="margin-right: 3px"
      value="Guardar"
    >
      Guardar
    </button>
  </mat-card-actions>
</mat-card>
