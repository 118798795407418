
<h1 mat-dialog-title> {{data.nombreCompleto | titlecase}}</h1>

<mat-dialog-content *appRole="['Personas.Ver']">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="personaForm">
        <div class="row">
          <div class="col">
            <p><strong>Estado: </strong>
              <mat-slide-toggle disabled="true" [(ngModel)]="data.activo"></mat-slide-toggle>
            </p>
          </div>
          <div class="col">
            <p><strong>Mas info: </strong>
              <a [routerLink]="['/personas/edit/'+data?.id]" [mat-dialog-close]="true"> <span>{{data?.nombreCompleto |
                  titlecase}}</span></a>
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p><strong>Nombre: </strong> {{data?.nombre}} <button class="icon-display" mat-icon-button
                (click)="copiarAlPortapapeles(data?.nombre)" matTooltip="Copiar al portapapeles"
                aria-label="Example icon button with a vertical three dot icon">
                <mat-icon>content_copy</mat-icon>
              </button></p>
          </div>
          <div class="col">
            <p><strong>Apellido:</strong> {{data?.apellido}} <button class="icon-display" mat-icon-button
                (click)="copiarAlPortapapeles(data?.apellido)" matTooltip="Copiar al portapapeles"
                aria-label="Example icon button with a vertical three dot icon">
                <mat-icon>content_copy</mat-icon>
              </button></p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p><strong>Nro. Documento:</strong> {{data?.nroDocumento}}</p>
          </div>
          <div class="col">
            <p><strong>Tipo Documento:</strong> {{data?.tipoDocumento?.descripcion
              }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p><strong>Correo:</strong> {{data?.correo}} <button class="icon-display" mat-icon-button
                (click)="copiarAlPortapapeles(data?.correo)" matTooltip="Copiar al portapapeles"
                aria-label="Example icon button with a vertical three dot icon">
                <mat-icon>content_copy</mat-icon>
              </button></p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p><strong>Teléfonos:</strong> {{data?.telefonoPrincipal}} | {{data?.telefonoLaboral}} |
              {{data?.telefonoAlternativo}} </p>

          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field appearance="outline" >
              <mat-label>Correo electrónico</mat-label>
              <input matInput  type="email" formControlName="CorreoElectronico" placeholder="Ingrese el correo electrónico">
              <mat-error *ngIf="personaForm.controls['CorreoElectronico'].hasError('required')">Este campo es
                <strong>requerido</strong>.</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <button mat-raised-button color="accent" [disabled]="!personaForm.valid" (click)="sendEmail()">
              <mat-icon>send</mat-icon> Enviar Correo
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field appearance="outline" >
              <mat-label>Número de teléfono</mat-label>
              <!-- <span matPrefix>+54 &nbsp;</span> -->
              <input matInput  placeholder="Sin 0 y sin 15" type="text" formControlName="Telefono" />

              <mat-error *ngIf="personaForm.controls['Telefono'].hasError('required')">Este campo es
                <strong>requerido</strong>.</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <button mat-raised-button color="accent" [disabled]="!personaForm.valid" (click)="sendWhatsApp()">
              <mat-icon>send</mat-icon> Enviar WS
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
