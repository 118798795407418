import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-corner-flag',
  templateUrl: './corner-flag.component.html',
  styleUrls: ['./corner-flag.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CornerFlagComponent {
  @Input() actionType!: 'view' | 'edit' | 'new'; // Tipo de bandera
  @Input() icon!: string; // Icono predeterminado

  getIconForAction(): string {
    switch (this.actionType) {
      case 'view':
        return 'visibility';
      case 'edit':
        return 'edit';
      case 'new':
        return 'add';
      default:
        return 'help_outline'; // Icono por defecto
    }
  }
}
