<mat-toolbar>
  <mat-toolbar-row class="mat-toolbar-row mat-toolbar-variant">
    <div class="menu-button-ajustado">
      <button
        mat-icon-button
        (click)="toggleDrawer()"
        class="example-icon"
        aria-label="Example icon-button with menu icon"
        style="background-color:  #009688; margin-left: -22px"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div
      class="flex justify-center items-center"
      style="background-color:  #009688; height: 100%; gap: 80px"
    >
      <span>GPerito</span>

      <app-breadcrumb
        style="
          background-color: #f4f4f4;
          height: 100%;
          border-bottom: 1px solid #4d4d4d4d;
        "
      ></app-breadcrumb>
    </div>
    <span class="example-spacer"></span>
    <!-- <app-buscar-general></app-buscar-general>
    <app-btn-notificaciones></app-btn-notificaciones> -->
    <nav-search-perfil-logo></nav-search-perfil-logo>
  </mat-toolbar-row>
</mat-toolbar>

<mat-drawer-container autosize="false" autoFocus="false">
  <mat-drawer #drawer mode="over" position="start" autoFocus="false">
    <app-menu-principal
      (toggleDrawerEvent)="toggleDrawer()"
    ></app-menu-principal>
  </mat-drawer>
  <mat-drawer-content>
    <!-- Contenido principal -->
    <div class="container-fluid p-3 my-3">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
