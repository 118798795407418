import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AccionesDialog } from '@models/base/identificadores.model';
import { ExpedienteTareaDto } from '@models/expedienteTarea/expedienteTareaDto.model';
import { TareaDto } from '@models/tarea/tareaDto.model';
import { ExpedienteTareaService } from '@services/expedienteTarea/expedienteTarea.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TareaService } from '@services/tarea/tarea.service';
import { DialogDeleteComponent } from 'src/app/component/dialog-delete/dialog-delete.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogCreateTareaComponent } from '../dialog-create-tarea/dialog-create-tarea.component';
import { FormBuilder } from '@angular/forms';
import { BaseDto } from '@models/base/baseDto.model';
import { TareaFilter } from '@models/tarea/tareaFilter.model';
import { DialogUpdateTareaComponent } from '../dialog-update-tarea/dialog-update-tarea.component';
import { DialogViewTareaComponent } from '../dialog-view-tarea/dialog-view-tarea.component';


@Component({
  selector: 'app-list-tarea',
  templateUrl: './list-tarea.component.html',
  styleUrls: ['./list-tarea.component.css']
})
export class ListTareaComponent {


  @Input() idExpediente!: number
  data = false
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<TareaDto>();
  formBuilder = new FormBuilder
  formFilter: any;
  checkedFiltroAvanzado: boolean = false;
  lstEstados: BaseDto[] = [
    { id: 0, descripcion: 'Todos' },
    { id: 1, descripcion: 'Pendiente' },
    { id: 2, descripcion: 'En Proceso' },
    { id: 3, descripcion: 'Finalizado' },
  ];
  lstPrioridades: BaseDto[] = [
    { id: 0, descripcion: 'Todos' },
    { id: 1, descripcion: 'Baja' },
    { id: 2, descripcion: 'Media' },
    { id: 3, descripcion: 'Alta' },
  ];

  lstNiveles: BaseDto[] = [
    { id: 0, descripcion: 'Todos' },
    { id: 1, descripcion: 'Baja' },
    { id: 2, descripcion: 'Media' },
    { id: 3, descripcion: 'Alta' },
  ];
  tareaFilter!: TareaFilter;
  constructor(
    private tareaService: TareaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["titulo", "accion"] :
        ["titulo", "expediente", "fechaAlta", "nivelAvance", "prioridad", "estado", "accion"];
    });

    this.formFilter = this.formBuilder.group({
      Titulo: [''],
      EstadoTarea: [''],
      Prioridad: [''],
      FechaAltaDesde: [Date],
      FechaAltaHasta: [Date],
    })

    this.dataSource.paginator = this.paginator;
  }

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.filterPredicate = (data: TareaDto, filter: string) => {
      return data.titulo.toLowerCase().includes(filter.trim().toLowerCase());
    };
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    if (this.idExpediente) {
      this.filtrar()
      this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
        this.displayedColumns = result.matches ?
          ["titulo", "accion"] :
          ["titulo", "expediente", "fechaAlta", "nivelAvance", "prioridad", "estado", "accion"];
      });
    }
    this.dataSource.paginator = this.paginator;

    this.dataSource.filterPredicate = (data: TareaDto, filter: string) => {
      return data.titulo.toLowerCase().includes(filter.trim().toLowerCase());
    };
  }



  filtrosAvanzados(event: any) {
    if (event.checked == true)
      this.checkedFiltroAvanzado = true
    else
      this.checkedFiltroAvanzado = false
  }

  filtrar() {
    this.tareaFilter = new TareaFilter
    this.tareaFilter.titulo = isFalsy(this.formFilter.controls['Titulo'].value) ? null : this.formFilter.controls['Titulo'].value;
    this.tareaFilter.idEstadoTarea = isFalsy(this.formFilter.controls['EstadoTarea'].value) ? null : this.formFilter.controls['EstadoTarea'].value;
    this.tareaFilter.prioridad = isFalsy(this.formFilter.controls['Prioridad'].value) ? null : this.formFilter.controls['Prioridad'].value;

    this.tareaFilter.fechaAltaDesde = isFalsy(this.formFilter.controls['FechaAltaDesde'].value) ? null : this.formFilter.controls['FechaAltaDesde'].value;
    this.tareaFilter.fechaAltaHasta = isFalsy(this.formFilter.controls['FechaAltaHasta'].value) ? null : this.formFilter.controls['FechaAltaHasta'].value;
    this.tareaFilter.idExpediente = this.idExpediente

    this.getAllTareaFilter(this.tareaFilter)
  }


  getAllTarea() {
    this.spinner.show('spListado')
    this.tareaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide('spListado')
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide('spListado')
          this.snackBar.showError(error, "Error");
        })
  }

  getAllTareaFilter(tareaFilter: TareaFilter) {
    this.spinner.show('spListado')
    this.tareaService.getAllByFilter(tareaFilter)
      .subscribe(
        data => {
          this.spinner.hide('spListado')
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide('spListado')
          this.snackBar.showError(error, "Error");
        })
  }

  getAllTareaByIdExpediente(idExpediente: number) {
    this.spinner.show('spListado')
    this.tareaService.getAllByIdExpediente(idExpediente)
      .subscribe(
        data => {
          this.spinner.hide('spListado')
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide('spListado')
          this.snackBar.showError(error, "Error");
        })
  }

  openDialogCreate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%"
    dialogConfig.autoFocus = false
    dialogConfig.data = this.idExpediente
    const dialogRef = this.dialog.open(DialogCreateTareaComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.getAllTareaFilter(this.tareaFilter);
      }
    });
  }

  openDialogView(obj: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%"
    dialogConfig.autoFocus = false
    dialogConfig.data = obj
    const dialogRef = this.dialog.open(DialogViewTareaComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDialogDelete(obj: any) {
    obj.descripcion = obj.titulo
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%"
    dialogConfig.autoFocus = false
    dialogConfig.data = obj
    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }

  openDialogUpdate(obj: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%"
    dialogConfig.autoFocus = false
    dialogConfig.data = obj
    const dialogRef = this.dialog.open(DialogUpdateTareaComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Modificar) {
        this.getAllTareaFilter(this.tareaFilter);
      }

    });
  }

  deleteRowData(id: number) {
    this.spinner.show('spListado')
    this.tareaService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide('spListado')
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          this.getAllTarea();
        },
        error => {
          this.spinner.hide('spListado')
          this.snackBar.showError(error, "Error");
        });
  }
  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
}
