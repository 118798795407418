import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-buscar-general-resultado',
  templateUrl: './buscar-general-resultado.component.html',
  styleUrls: ['./buscar-general-resultado.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuscarGeneralResultadoComponent {
  query: string = '';
  results: string[] = []; // Aquí cargas los resultados

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.query = params['query'] || '';
      this.performSearch();
    });
  }

  performSearch() {
    // Simula una búsqueda (reemplazar con lógica real)
    const data = ['Elemento 1', 'Elemento 2', 'Elemento 3', 'Elemento 4'];
    this.results = data.filter(item => item.toLowerCase().includes(this.query.toLowerCase()));
  }
}
