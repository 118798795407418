import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared.module';
import { DialogNotificacionesComponent } from '@views/notificaciones/dialog-notificaciones/dialog-notificaciones.component';
import { BtnNotificacionesComponent } from '@views/notificaciones/btn-notificaciones/btn-notificaciones.component';



@NgModule({
  declarations: [
    BtnNotificacionesComponent,
    DialogNotificacionesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports:[
    BtnNotificacionesComponent,
    DialogNotificacionesComponent,
    ]
})
export class NotificacionModule { }
