import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SharedModule } from '@modules/shared.module';

@Component({
  selector: 'app-button-close-dialog',
  standalone: true,
  imports: [SharedModule],
  template: `<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>`,
  styleUrls: ['./button-close-dialog.component.css'],
})
export class ButtonCloseDialogComponent { }
