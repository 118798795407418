<div class="search-results">
  <h2>Resultados para "{{ query }}"</h2>
  <div *ngIf="results.length > 0; else noResults">
    <ul>
      <li *ngFor="let result of results">
        {{ result }}
      </li>
    </ul>
  </div>
  <ng-template #noResults>
    <p>No se encontraron resultados.</p>
  </ng-template>
</div>
