<h1 mat-dialog-title>Acción realizada :: <strong>Ver</strong></h1>
<mat-dialog-content>
  <ngx-spinner
    [zIndex]="100"
    name="spView"
    bdColor="rgba(255,255,255,0.8)"
    size="default"
    color=" #009688"
    type="ball-atom"
    [fullScreen]="false"
  >
  </ngx-spinner>
  <div class="div">
    <div class="row">
      <div class="col">
        <p><b>Titulo:</b> {{ tareaDto.titulo }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p>
          <b>Estado:</b>
          {{
            tareaDto.estadoTarea == undefined
              ? ""
              : tareaDto.estadoTarea.descripcion
          }}
        </p>
      </div>
      <div class="col">
        <p>
          <b>Prioridad:</b>
          {{
            tareaDto.prioridad == undefined
              ? ""
              : tareaDto.prioridad == 1
              ? "Baja"
              : tareaDto.prioridad == 2
              ? "Media"
              : "Alta"
          }}
        </p>
      </div>
      <div class="col">
        <p>
          <b>Nivel Avance:</b>
          {{
            tareaDto.nivelAvance == undefined
              ? ""
              : tareaDto.nivelAvance == 1
              ? "Baja"
              : tareaDto.nivelAvance == 2
              ? "Media"
              : "Alta"
          }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p><b>Descripción:</b></p>
        <p>
          {{ tareaDto.descripcion == undefined ? "" : tareaDto.descripcion }}
        </p>
      </div>
    </div>
  </div>
</mat-dialog-content>
