import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { SharedModule } from '@modules/shared.module';
import { ButtonCloseComponent } from '../button-close/button-close.component';
import { ButtonCloseDialogComponent } from '../button-close-dialog/button-close-dialog.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CuerpoDialogCrudRead } from '@models/base/cuerpoDialogCrudRead.model';



@Component({
  selector: 'app-dialog-crud-read',
  standalone: true,
  imports: [SharedModule, ButtonCloseComponent,ButtonCloseDialogComponent],
  styleUrls: ['./dialog-crud-read.component.css'],
  templateUrl: './dialog-crud-read.component.html',
})
export class DialogCrudReadComponent {

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: CuerpoDialogCrudRead) { }

  openDialog() {
    this.dialog.open(DialogCrudReadComponent);
  }
  ngOnInit(): void {
  }

}
