import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioDto } from '@models/usuario/usuarioDto.model';
import { LoginService } from '@services/login/login.service';
import { UsuarioService } from '@services/usuario/usuario.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css'],
  animations: [
    trigger('scrollAnimation', [
      state(
        'scrolled',
        style({
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          top: 0,
          backgroundColor: '#fafafa',
        })
      ),
      state(
        'normal',
        style({
          position: 'relative',
        })
      ),
      transition('scrolled => normal', animate('700ms ease-out')),
      transition('normal => scrolled', animate('700ms ease-in')),
    ]),
  ],
})
export class InicioComponent implements OnInit {
  UsuarioDto!: UsuarioDto;
  state = 'normal';
  menu = false;
  @ViewChild('drawer', { static: false }) drawer!: MatDrawer;
  isOpened: boolean = true;
  desktopViewWidth: number = 2100;

  constructor(
    public el: ElementRef,
    private userService: UsuarioService,
    private router: Router,
    private loginService: LoginService
  ) {}

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const componentPosition = this.el.nativeElement.offsetTop;
    const scrollPosition = window.pageYOffset;

    if (scrollPosition >= componentPosition) {
      this.state = 'scrolled';
    } else {
      this.state = 'normal';
    }
  }

  ngOnInit(): void {
    this.onResize(window.innerWidth);
    const userDto = this.loginService.getCurrentUser();
    if (!userDto) {
      this.loginService.logout();
      this.router.navigate(['/login']);
    }
  }

  toggleDrawer() {
    this.drawer.toggle();
    this.menu = true;
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    this.isOpened = width >= this.desktopViewWidth;
  }
}
