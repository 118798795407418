import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogCreateUpdateDeleteComponent } from '../component/dialog-create-update-delete/dialog-create-update-delete.component';
import { SharedModule } from 'src/app/modules/shared.module';
import { DialogDeleteComponent } from '../component/dialog-delete/dialog-delete.component';
import { GenericDialogComponent } from '../component/generic-dialog/generic-dialog.component';
import { DialogNuevoEmailComponent } from 'src/app/component/dialog-nuevo-email/dialog-nuevo-email.component';
import { MenuesDesplegablesComponent } from '@views/menues-desplegables/menues-desplegables.component';
import { ListTipoPericiaComponent } from '@views/tipos-pericia/list-tipo-pericia/list-tipo-pericia.component';
import { CornerFlagComponent } from 'src/app/component/corner-flag/corner-flag.component';
import { PaginatorComponent } from 'src/app/component/paginator/paginator.component';
import { SinDatosParaMostrarComponent } from 'src/app/component/sin-datos-para-mostrar/sin-datos-para-mostrar.component';
import { BuscarGeneralComponent } from '@views/busquedas/buscar-general/buscar-general.component';
import { BuscarGeneralResultadoComponent } from '@views/busquedas/buscar-general-resultado/buscar-general-resultado.component';



@NgModule({
  declarations: [
    DialogCreateUpdateDeleteComponent,
    DialogDeleteComponent,
    GenericDialogComponent,
    DialogNuevoEmailComponent,
    MenuesDesplegablesComponent,
    CornerFlagComponent,
    PaginatorComponent,
    BuscarGeneralResultadoComponent,
    BuscarGeneralComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports:[
    DialogCreateUpdateDeleteComponent,
    DialogDeleteComponent,
    GenericDialogComponent,
    DialogNuevoEmailComponent,
    MenuesDesplegablesComponent,
    CornerFlagComponent,
    PaginatorComponent,
    BuscarGeneralResultadoComponent,
    BuscarGeneralComponent
  ]
})
export class GenericoModule { }
