import { CommonModule } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ApplicationModule, CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { NgxSpinnerModule } from "ngx-spinner";
// import { ProgressModule } from "../views/progress/progress.module";
import { DndDirective } from "../directives/dnd.directive";
import { SnackBarService } from "../services/snackBar/snack-bar-service.service";
import { MaterialAngularModule } from "./materialAngular.module";
import { NgxEditorModule } from "ngx-editor";
import { LoadingBtnDirective } from "../directives/loading-btn/loading-btn.directive";
import { AppRoleDirective } from "../directives/appRole/app-role.directive";
import { AppTestComponent } from "@views/app-test/app-test.component";
import { AlertsComponent } from "@views/alerts/alerts.component";
import { ProgressModule } from "@views/progress/progress.module";
import { NavPanelPersonaComponent } from "@views/personas/nav-panel-persona/nav-panel-persona.component";
import { NgxCurrencyModule } from "ngx-currency";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { SinDatosParaMostrarComponent } from "src/app/component/sin-datos-para-mostrar/sin-datos-para-mostrar.component";
import { MatTableExporterModule } from "mat-table-exporter";
import { DynamicPipe } from "@pipes/dynamicPipe.pipe";

@NgModule({
  imports: [
    CommonModule,
    ApplicationModule,
    BrowserAnimationsModule,
    MaterialAngularModule,
    FormsModule,
    RouterModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    HttpClientModule,
    ProgressModule,
    NgxEditorModule,
    NgxCurrencyModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatTableExporterModule,
  ],
  declarations: [
    NavPanelPersonaComponent,
    DndDirective,//DragAndDrop
    LoadingBtnDirective,
    AppRoleDirective,
    AppTestComponent,
    AlertsComponent,
    SinDatosParaMostrarComponent,
    DynamicPipe
  ],
  providers: [
    SnackBarService,
    HttpClient,
    provideNgxMask()
  ],
  exports: [
    CommonModule,
    ApplicationModule,
    BrowserAnimationsModule,
    MaterialAngularModule,
    FormsModule,
    RouterModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    HttpClientModule,
    NavPanelPersonaComponent,
    ProgressModule,
    AlertsComponent,
    NgxEditorModule,
    //Directivas
    LoadingBtnDirective,
    DndDirective,//DragAndDrop
    AppRoleDirective,
    NgxCurrencyModule,
    SinDatosParaMostrarComponent,
    MatTableExporterModule,
    //Pipes
    DynamicPipe

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule { }

