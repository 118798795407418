import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '@modules/shared.module';

@Component({
  selector: 'app-button-close',
  standalone: true,
  imports: [SharedModule],
  template: `<button mat-stroked-button color="primary" [disabled]="disabled" name="Cerrar">
  <mat-icon>close</mat-icon> Cerrar
</button>`,
  styleUrls: ['./button-close.component.css'],
})
export class ButtonCloseComponent {
  @Input() disabled! : any
}
