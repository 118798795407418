import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasoDto } from '@models/caso/casoDto.model';
import { ExpedienteDto } from '@models/expediente/expedienteDto.model';
import { CasoService } from '@services/caso/caso.service';
import { ExpedienteService } from '@services/expediente/expediente.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-form-caso-view',
  templateUrl: './form-caso-view.component.html',
  styleUrls: ['./form-caso-view.component.css'],
})
export class FormCasoViewComponent {
  casoDto!: CasoDto;
  expedientes!: ExpedienteDto[];
  constructor(
    private casoService: CasoService,
    private router: Router,
    private expedienteService: ExpedienteService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute

  ) { }

  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.params['id']
    this.getById(id)
  }

  async getById(id: number) {
    this.spinner.show("spView")
    await this.casoService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spView")
          this.casoDto = data
        },
        error => {
          this.spinner.hide("spView")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllByCaso(idCaso: number) {
    this.spinner.show("spView")
    this.expedienteService.getAllByCaso(idCaso)
      .subscribe(
        data => {
          this.spinner.hide("spView")
          this.expedientes = data
        },
        error => {
          this.spinner.hide("spView")
          this.snackBar.showError(error, "Error");
        })
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }
}
