import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseDto } from '@models/base/baseDto.model';
import { ExpedienteSummaryDto } from '@models/expediente/expedienteSummary.model';
import { CreatePericiaRequest } from '@models/pericia/createPericiaRequest.model';
import { EstadoPericiaService } from '@services/estadoPericia/estadoPericia.service';
import { PericiaService } from '@services/pericia/pericia.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoPericiaService } from '@services/tipoPericia/tipoPericia.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogCreatePericiaComponent } from '../dialog-create-pericia/dialog-create-pericia.component';

@Component({
  selector: 'app-form-pericia-create',
  templateUrl: './form-pericia-create.component.html',
  styleUrls: ['./form-pericia-create.component.css']
})
export class FormPericiaCreateComponent {
  formBuilder: FormBuilder = new FormBuilder;
  tiposAudiencia!: BaseDto[];
  periciaForm: any;
  idExpediente!: number;
  lstTiposPericia!: BaseDto[];
  lstEstadosPericias!: BaseDto[];
  @Input() isDialog = false
  lugaresFrecuentes!: string[];

  constructor(
    private PericiaService: PericiaService,
    private estadosPericia: EstadoPericiaService,
    private tiposPericia:TipoPericiaService,
    private router: Router,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,

  ) { }

  ngOnInit(): void {

    this.getAllTipoPericia()
    this.getAllEstadosPericia()
    this.getAllLugaresFrecuentes()

    this.periciaForm = this.formBuilder.group({
      Descripcion: [''],
      Lugar: ['',Validators.compose([Validators.required, Validators.maxLength(200)])],
      NotificacionRealizada: [''],
      FechaPropuesta: ['', Validators.compose([Validators.required])],
      FechaRealizada: [''],
      FechaFinalizada: [''],
      TipoPericia: [''],
      EstadoPericia: [''],
    });
  }


  onSubmit() {
    if (this.periciaForm.invalid) {
      return;
    }
    const createPericiaRequest: CreatePericiaRequest = this.setCreatePericiaRequest();
    this.spinner.show("spCreate")
    this.PericiaService.create(createPericiaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spCreate")
          this.snackBar.showSuccess("Se agrego el registro correctamente.", "");
          this.router.navigate(["/pericias/" + data.entidad.id])
        },
        error => {
          this.spinner.hide("spCreate")
          this.snackBar.showError(error, "Error");
        })
  }

  setCreatePericiaRequest(): CreatePericiaRequest {

    const createPericiaRequest = new CreatePericiaRequest();

    createPericiaRequest.descripcion = this.periciaForm.controls['Descripcion'].value;
    createPericiaRequest.lugar = this.periciaForm.controls['Lugar'].value;
    createPericiaRequest.notificacionRealizada = isFalsy(this.periciaForm.controls['NotificacionRealizada'].value) ? false: true;
    createPericiaRequest.fechaRealizada = this.periciaForm.controls['FechaRealizada'].value;
    createPericiaRequest.fechaPropuesta = this.periciaForm.controls['FechaPropuesta'].value == "" ? null : this.periciaForm.controls['FechaPropuesta'].value;
    createPericiaRequest.fechaFinalizada = this.periciaForm.controls['FechaFinalizada'].value == "" ? null : this.periciaForm.controls['FechaFinalizada'].value;
    createPericiaRequest.idEstadoPericia = this.periciaForm.controls['EstadoPericia'].value == "" ? null : this.periciaForm.controls['EstadoPericia'].value;
    createPericiaRequest.idTipoPericia = this.periciaForm.controls['TipoPericia'].value == ""? null : this.periciaForm.controls['TipoPericia'].value ;
    createPericiaRequest.idExpediente = this.idExpediente

    createPericiaRequest.idEmpresa = 1
    createPericiaRequest.activo = true
    return createPericiaRequest
  }

  onReset() {
    this.periciaForm.reset();
  }


  getAllLugaresFrecuentes() {
    this.spinner.show('spLugaresFrecuentes')
    this.PericiaService.getAllLugaresFrecuentes()
      .subscribe(
        data => {
          this.spinner.hide('spLugaresFrecuentes')
          this.lugaresFrecuentes = data
        },
        error => {
          this.spinner.hide('spLugaresFrecuentes')
          this.snackBar.showError(error, "Error");
        })
  }
  getAllTipoPericia() {
    this.spinner.show('spTiposPericia')
    this.tiposPericia.getAll()
      .subscribe(
        data => {
          this.spinner.hide('spTiposPericia')
          this.lstTiposPericia = data
        },
        error => {
          this.spinner.hide('spTiposPericia')
          this.snackBar.showError(error, "Error");
        })
  }

  getAllEstadosPericia() {
    this.spinner.show('spEstadosPericia')
    this.estadosPericia.getAll()
      .subscribe(
        data => {
          this.spinner.hide('spEstadosPericia')
          this.lstEstadosPericias = data
        },
        error => {
          this.spinner.hide('spEstadosPericia')
          this.snackBar.showError(error, "Error");
        })
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

  selectExpediente(expedienteSummaryDto: ExpedienteSummaryDto) {
    this.idExpediente = expedienteSummaryDto.id;
  }


}
