<button
  mat-raised-button
  color="primary"
  *appRole="['Roles.Crear']"
  (click)="openDialogCreate()"
  color="primary"
>
  <mat-icon>add</mat-icon>
  Nuevo Rol</button
>&nbsp;
<br />
<br />

<mat-card *appRole="['Roles.Ver']">
  <mat-card-header>
    <div class="row align-items-center">
      <div class="col-auto">
        <button mat-stroked-button color="primary" (click)="filtrar()">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
        </button>
      </div>
      <div class="col-auto">
        <mat-slide-toggle (change)="filtrosAvanzados($event)" color="primary">
          Filtros Avanzados
        </mat-slide-toggle>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <ngx-spinner
      [zIndex]="100"
      name="spRoles"
      bdColor="rgba(255,255,255,0.8)"
      size="default"
      color=" #009688"
      type="ball-atom"
      [fullScreen]="false"
    >
    </ngx-spinner>
    <div class="row align-items-center">
      <div class="col">
        <!-- <button mat-raised-button color="primary" *ngIf="data">
            <mat-icon>more_vert</mat-icon>
            Acciones
          </button> -->
      </div>

      <div class="col-lg-4 col-sm-12 text-end">
        <mat-form-field appearance="outline" *ngIf="data" class="w-100">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Buscar</mat-label>
          <input
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Ex. ium"
            #input
          />
        </mat-form-field>
      </div>
    </div>

    <table
      class="mat-elevation-z8"
      mat-table
      [dataSource]="dataSource"
      *ngIf="data"
      table
      matSort
      matSortStart="asc"
    >
      <ng-container matColumnDef="descripcion">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header
          ><strong>Descripción</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          <span>{{ element.descripcion }}</span>
        </mat-cell>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="accion">
        <mat-header-cell mat-header-cell *matHeaderCellDef>
          <strong>Acción</strong>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element" class="action-link">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              *appRole="['Roles.Ver']"
              matTooltip="Ver"
              matTooltipPosition="right"
              (click)="openDialogVer(element)"
            >
              <mat-icon>visibility</mat-icon>
              <span>Ver</span>
            </button>
            <button
              mat-menu-item
              *appRole="['Roles.Modificar']"
              matTooltip="Editar"
              matTooltipPosition="right"
              (click)="openDialogUpdate(element)"
            >
              <mat-icon>edit</mat-icon>
              <span>Editar</span>
            </button>
            <button
              mat-menu-item
              *appRole="['Roles.Eliminar']"
              matTooltip="Eliminar"
              matTooltipPosition="right"
              (click)="openDialogDelete(element)"
            >
              <mat-icon>delete</mat-icon>
              <span>Eliminar</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        matRipple
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
    </table>
    <app-sin-datos-para-mostrar
      [dataSource]="dataSource"
    ></app-sin-datos-para-mostrar>
    <mat-paginator
      showFirstLastButtons
      class="paginator"
      *ngIf="data"
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100, 200]"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>
