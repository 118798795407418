import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, DecimalPipe, CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'dynamicPipe',
  pure: true
})
export class DynamicPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe
  ) {}

  transform(value: any, pipeName: string, args?: any): any {
    switch (pipeName) {
      case 'uppercase':
        return value?.toUpperCase();
      case 'lowercase':
        return value?.toLowerCase();
      case 'date': // Formatear fechas
        const dateFormat = args?.format || 'dd/MM/yyyy'; // Formato por defecto
        return this.datePipe.transform(value, dateFormat);
      case 'currency': // Formatear moneda
        const currencyCode = args?.currency || 'ARS'; // Moneda por defecto: Peso argentino
        const symbolDisplay = args?.symbol || 'symbol'; // Mostrar símbolo ($) por defecto
        const digits = args?.digits || '1.2-2'; // 2 decimales por defecto
        return this.currencyPipe.transform(value, currencyCode, symbolDisplay, digits);
      case 'number': // Formatear números
        const numberFormat = args || '1.0-2'; // Por defecto, 2 decimales
        return this.decimalPipe.transform(value, numberFormat);
      default:
        return value; // Retorna el valor original si no se reconoce el pipe
    }
  }
}
