<app-button-close-dialog></app-button-close-dialog>
<h1 mat-dialog-title>Acción realizada :: <strong>Eliminar</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  ¿Esta seguro que desea eliminar el registro {{local_data.descripcion != "" || null ? local_data.descripcion : ""}}?
  <br>
  <br>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions align="end">
  <app-button-cancel mat-dialog-close (click)="closeDialog()"></app-button-cancel>
  <app-button-delete (click)="doAction()"></app-button-delete>
</div>
