import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Roles } from '@models/base/identificadores.model';
import { LoginService } from '@services/login/login.service';
import { NotificacionesService } from '@services/notificaciones/notificaciones.service';
import { DialogNotificacionesComponent } from '../dialog-notificaciones/dialog-notificaciones.component';
import { NotificacionDto } from '@models/notificacion/notificacionDto.model';

@Component({
  selector: 'app-btn-notificaciones',
  templateUrl: './btn-notificaciones.component.html',
  styleUrls: ['./btn-notificaciones.component.css']
})
export class BtnNotificacionesComponent implements OnInit {
  usuarioCliente: boolean = false;
  notificaciones: NotificacionDto[] = []
  constructor(
    public dialog: MatDialog,
    private loginService: LoginService,
    private notificacionesService: NotificacionesService,
  ) { }


  ngOnInit(): void {
    if (this.loginService.getIdUserRol() == Roles.Clientes) {
      this.usuarioCliente = true
    }
    //this.agregarNotificacionObservable()
  }

  // agregarNotificacionObservable() {
  //   this.notificacionesService.enviarNotificacionObservable.subscribe(response => {
  //     if (this.notificaciones.length > 0) {
  //       if (this.notificaciones.filter(x => x.mensaje != response.mensaje))
  //         this.notificaciones.push(response)
  //     }
  //     else
  //       this.notificaciones.push(response)
  //   })
  // }


  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%"
    dialogConfig.data = this.notificaciones

    const dialogRef = this.dialog.open(DialogNotificacionesComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }




}
