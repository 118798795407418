import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '@modules/shared.module';
import { ButtonCancelComponent } from '../button-cancel/button-cancel.component';

@Component({
  selector: 'app-button-save',
  standalone: true,
  templateUrl:'./button-save.component.html',
  styleUrls: ['./button-save.component.css'],
  imports:[SharedModule,ButtonCancelComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonSaveComponent {
  @Input() disabled! : any
}
