import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedModule } from '@modules/shared.module';
import { NavigationService } from '@services/navigation-service/navigation-service.service';

@Component({
  selector: 'app-button-cancel',
  standalone: true,
  imports: [SharedModule],
  template: `<button mat-stroked-button
  style="margin-right: 5px; margin-left: 5px"
  color="primary"
  (click)="goBack()"
  [disabled]="disabled"
  name="Cancelar">
Cancelar
</button>`,
  styleUrls: ['./button-cancel.component.css'],
})
export class ButtonCancelComponent {
  @Input() disabled!: any

  constructor(private navigationService: NavigationService,
    private route: ActivatedRoute, private router: Router
  ) { }
  goBack(): void { this.navigationService.back(); }
}
