import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GetFileContentByIdArchivoRequest } from '@models/archivo/getFileContentByIdArchivoRequest.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { DocumentoDto } from '@models/documento/documentoDto.model';
import { ArchivoService } from '@services/archivo/archivo.service';
import { DocumentoService } from '@services/documento/documento.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogDeleteComponent } from 'src/app/component/dialog-delete/dialog-delete.component';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'listado-documentos',
  templateUrl: './listado-documentos.component.html',
  styleUrls: ['./listado-documentos.component.css']
})
export class ListadoDocumentosComponent implements OnInit, OnChanges {
  data = false
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<DocumentoDto>();
  errorMessage: string = "";
  getFileContentByIdArchivoRequest!: GetFileContentByIdArchivoRequest
  @Input() idEntidad: number = 0
  @Input() idTipoEntidad: number = 0

  constructor(
    private DocumentoService: DocumentoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private ArchivoService: ArchivoService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["nombre", "descargar", "accion"] :
        ["nombre", "asunto", "tipoEntidad", "descargar", "accion"];
    });
  }

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }


  ngOnInit(): void {
    this.getAllDocumentos();
  }

  ngOnChanges(): void {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.data = this.dataSource.data.length >= 1 ? true : false
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllDocumentos() {
    this.spinner.show("spListadoDoc")
    this.DocumentoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spListadoDoc");
          this.dataSource.data = data.sort((a, b) => a.nombre.toString().localeCompare(b.nombre.toString())) as DocumentoDto[]
          this.data = this.dataSource.data.length >= 1 ? true : false

        },
        error => {
          this.spinner.hide("spListadoDoc");
          this.snackBar.showError(error, "Error");
        })
  }

  descargarArchivo(documento: DocumentoDto) {
    this.spinner.show("spListadoDoc");
    this.ArchivoService.getFileContentByIdArchivo(documento.archivo.id).subscribe
      (
        data => {
          this.spinner.hide("spListadoDoc");
          this.getFileContentByIdArchivoRequest = data
          this.downloadFile(data, documento)
        },
        error => {
          this.spinner.hide("spListadoDoc");
          this.snackBar.showError(error, "Error");
        }
      )

  }

  downloadFile(getFileContentByIdArchivoRequest: GetFileContentByIdArchivoRequest, documento: DocumentoDto) {
    // const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    let tipoArchivo: string = ""
    link.href = getFileContentByIdArchivoRequest.content;
    switch (getFileContentByIdArchivoRequest.contentType) {
      case "application/pdf": {
        tipoArchivo = ".pdf"
        break;
      }
      case "image/png": {
        tipoArchivo = ".png"
        break;
      }
      case "image/jpeg": {
        tipoArchivo = ".jpeg"
        break;
      }
      case "application/msword": {
        tipoArchivo = ".doc"
        break;
      }
      case "application/vnd.ms-excel": {
        tipoArchivo = ".xls"
        break;
      }
      case "application/image/gif": {
        tipoArchivo = ".gif"
        break;
      }
    }
    link.download = documento.descripcion.trim() + tipoArchivo
    link.click();
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = obj
    dialogConfig.width = "50%"

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.eliminarDocumento(result.data);
        this.getAllDocumentos()
      }
    });
  }

  eliminarDocumento(id: any) {
    this.DocumentoService.quitarAsociacionDocumentoAEntidad(id).subscribe
      (
        data => {
          this.spinner.hide("spListadoDoc");
          this.snackBar.showSuccess("Registro eliminado correctamente.", "Exito");
        },
        error => {
          this.spinner.hide("spListadoDoc");
          this.snackBar.showError(error, "Error");
        }
      )
  }

}
