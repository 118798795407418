import { PersonaDetailDto } from "@models/persona/personaDetailDto.model";
import { BaseDto } from "../base/baseDto.model";
import { RolDto } from "../seguridad/roles/rolDto.model";
import { EmpresaSummaryDto } from "@models/empresa/empresaSummaryDto.model";


export class UsuarioDto extends BaseDto{

    public activo? : boolean;
    public userName! : string ;
    public password!:string;
    public nombre!:string;
    public correo!:string;
    public telefono!:string;
    public apellido!:string ;
    public rol!: RolDto;
    public changePassword? : boolean;
    public empresa!: EmpresaSummaryDto ;

}
