//Mascaras
import { CurrencyMaskConfig, CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
//Editor HTML
import { NgxEditorModule } from 'ngx-editor';
import { DialogDescripcionComponent } from '../component/dialog-descripcion/dialog-descripcion.component';
//https://www.npmjs.com/package/mat-table-exporter
import { MatTableExporterModule } from "mat-table-exporter";
//Notificaciones
import { SnackbarComponent } from '../views/snackbar/snackbar.component';

//Crear PDF
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { ErrorModule } from './error.modules';
import { SeguridadModule } from './seguridad.module';
import { EmailModule } from './email.module';
// import { reducer } from '../redux/app.reducers';
import { MyMonitoringService } from '@services/microsoft/applicationInsights.services';
// import { RollbarErrorHandlerService, rollbarFactory, RollbarService } from '@services/error/rollbarErrorHandler.service';


import { AppRoutingModule } from '../app-routing.module';
import { AuthGuardService } from '@guards/auth-guard/auth-guard.service';
import { SpinnerComponent } from '@views/spinner/spinner.component';
import { DocumentoModule } from './documento.module';
import { GenericoModule } from './generico.module';
import { ExpedienteModule } from './expediente.module';
import { AudienciaModule } from './audiencia.module';
import { PericiaModule } from './pericia.module';
import { CasoModule } from './caso.module';
import { ExpedienteNotaModule } from './expedienteNota.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner'; import { AppComponent } from '../app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';


// importar locales
import localePy from '@angular/common/locales/es-PY';
import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';
import localeEsAr from '@angular/common/locales/es-AR';


//App
import { BreadcrumbComponent } from '../views/breadcrumb/breadcrumb.component';
import { MyLoaderComponent } from '../views/my-loader/my-loader.component';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { InicioComponent } from '../views/inicio/inicio.component';
import { PercentageDirective } from '../directives/porcentaje.directive';
import { CoreModule } from './core.module';
import { SharedModule } from './shared.module';
import { PersonaModule } from './persona.module';
import { UsuarioModule } from './usuario.module';
import { LocalidadModule } from './localidad.module';
import { LoginModule } from '../views/login/login.module';
import { CanDeactivateGuard } from '../directives/can-deactivate/can-deactivate.guard';
import { GlobalHttpErrorInterceptorService } from '../interceptors/error-interceptor/error-interceptor.service';
import { LayoutModule } from '@angular/cdk/layout';
import { NavSearchPerfilLogoModule } from './navSearchPerfilLogo.module';
import { FilterPipe } from '../pipes/filter.pipe';
import { ArchivoModule } from './archivo.module';
import { ConfiguracionModule } from './configuracion.module';
import { DashboardModule } from './dashboard.module';
import { NotificacionModule } from './notificacion.module';
import { HotToastModule } from '@ngneat/hot-toast';
import { TipoPericiaModule } from "./tipoPericia.module";
import { TipoMovimientoModule } from "./tipoMovimiento.module";


// registrar los locales con el nombre que quieras utilizar a la hora de proveer
registerLocaleData(localePy, 'es');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEn, 'en')
registerLocaleData(localeEsAr, 'es-Ar');

//ngx-markdown
// import 'prismjs';
// import 'prismjs/components/prism-typescript.min.js';
// import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
// import 'prismjs/plugins/line-highlight/prism-line-highlight.js';

//Breadcrum
// import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";


//Manejo de Errores
import { AuthInterceptorService } from 'src/app/interceptors/auth/auth-interceptor.service';
import { OrganismoModule } from "./organismo.module";
import { BreadcrumbModule } from "xng-breadcrumb";
import { CalendarioModule } from "./calendario.module";
import { TareaModule } from "./tarea.module";

//Graficos
import { Chart } from "chart.js";
import { FullCalendarModule } from "@fullcalendar/angular";
import { ContactoModule } from "./contacto.module";
import { MainToolbarComponent } from "@views/main-toolbar/main-toolbar.component";
import { ComponentModule } from "./component.module";
import { BrowserModule } from "@angular/platform-browser";


//Configuracion Mascaras

export const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

const maskConfig: Partial<IConfig> = {
  validation: false,
};

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

// Microsfot Application Insights
// @Injectable()
// export class ErrorHandlerService extends ErrorHandler {

//   constructor(@Inject(MyMonitoringService) private myMonitoringService: MyMonitoringService) {
//     super();
//   }
//   handleErrorInsights(error: Error) {
//     this.myMonitoringService.logException(error); // Manually log exception
//   }
// }

@NgModule({
  imports: [
    CoreModule,
    BrowserModule,
    SharedModule,
    ErrorModule,
    PersonaModule,
    ExpedienteModule,
    UsuarioModule,
    LocalidadModule,
    DocumentoModule,
    LoginModule,
    ArchivoModule,
    LayoutModule,
    DashboardModule,
    ConfiguracionModule,
    NavSearchPerfilLogoModule,
    NotificacionModule,
    EmailModule,
    SeguridadModule,
    AudienciaModule,
    PericiaModule,
    CasoModule,
    ExpedienteNotaModule,
    TipoMovimientoModule,
    TipoPericiaModule,
    OrganismoModule,
    CalendarioModule,
    TareaModule,
    ContactoModule,
    ComponentModule,
    //BreadcrumbModule https://udayvunnam.github.io/xng-breadcrumb/#/quickstart
    BreadcrumbModule,
    //NGX Mask https://www.npmjs.com/package/ngx-mask
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    //Breadcrum
    //NgDynamicBreadcrumbModule,
    //Spinner https://www.npmjs.com/package/ngx-spinner
    BrowserAnimationsModule,
    NgxSpinnerModule,
    //
    AppRoutingModule,
    ReactiveFormsModule,
    GenericoModule,
    //Editor HTML
    NgxEditorModule,

    //Calendar
    FullCalendarModule,

    //Exportar Excel Tabla
    MatTableExporterModule,

    //Notifaciones : https://github.com/ngneat/hot-toast#installation
    HotToastModule.forRoot(
      {
        reverseOrder: true,
        dismissible: true,
        position: 'bottom-right',
        autoClose: true,
      }
    )
    //Redux
    // StoreModule.forRoot(reducer),
    // StoreDevtoolsModule.instrument({
    //   maxAge: 5,
    //   logOnly: environment.production
    // })


  ],
  declarations: [
    AppComponent,
    InicioComponent,
    MyLoaderComponent,
    SpinnerComponent,
    PercentageDirective,
    SnackbarComponent,
    FilterPipe,
    DialogDescripcionComponent,
    BreadcrumbComponent,
    MainToolbarComponent
  ],
  providers: [
    AuthGuardService,
    CanDeactivateGuard,
    AuthInterceptorService,
    MyMonitoringService,
    //locales
    { provide: LOCALE_ID, useValue: 'es-Ar' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: '$' },
    //RollbarService
    // { provide: ErrorHandler, useClass: RollbarErrorHandlerService },
    // { provide: RollbarService, useFactory: rollbarFactory },

    // Microsfot Application Insights
    //{ provide: ErrorHandler, useClass: ErrorHandlerService },
    //Error Service
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpErrorInterceptorService, multi: true },
    provideEnvironmentNgxMask(maskConfigFunction),

    //Autenticacion
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  exports: [
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
