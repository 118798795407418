import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BuscarExpedienteComponent } from "@views/busquedas/buscar-expediente/buscar-expediente.component";
import { DashboardExpedienteComponent } from "@views/expedientes/dashboard-expediente/dashboard-expediente.component";
import { FormExpedienteCreateComponent } from "@views/expedientes/form-expediente-create/form-expediente-create.component";
import { FormExpedienteUpdateComponent } from "@views/expedientes/form-expediente-update/form-expediente-update.component";
import { FormExpedienteViewComponent } from "@views/expedientes/form-expediente-view/form-expediente-view.component";
import { ListExpedienteComponent } from "@views/expedientes/list-expediente/list-expediente.component";
import { NavPanelExpedienteComponent } from "@views/expedientes/nav-panel-expediente/nav-panel-expediente.component";
import { ArchivoModule } from "./archivo.module";
import { PersonaModule } from "./persona.module";
import { SharedModule } from "./shared.module";
import { BusquedasModule } from "./busquedas.module";
import { FormExpedientePartesComponent } from "@views/expedientes/form-expediente-partes/form-expediente-partes.component";
import { ExpedienteNotaModule } from "./expedienteNota.module";
import { MovimientoModule } from "./movimiento.module";
import { TareaModule } from "./tarea.module";
import { DialogExpedientesEnviadosComponent } from "@views/expedientes/dialog-expedientes-enviados/dialog-expedientes-enviados.component";
import { ComponentModule } from "./component.module";
@NgModule({
  imports: [
      CommonModule,
      SharedModule,
      PersonaModule,
      ArchivoModule,
      BusquedasModule,
      ExpedienteNotaModule,
      MovimientoModule,
      TareaModule,
      ComponentModule
  ],
  declarations: [
    FormExpedienteCreateComponent,
    FormExpedienteUpdateComponent,
    FormExpedienteViewComponent,
    ListExpedienteComponent,
    NavPanelExpedienteComponent,
    DashboardExpedienteComponent,
    FormExpedientePartesComponent,
    DialogExpedientesEnviadosComponent
  ],

  providers: [

  ],
  exports: [
    FormExpedienteCreateComponent,
    FormExpedienteUpdateComponent,
    FormExpedienteViewComponent,
    ListExpedienteComponent,
    NavPanelExpedienteComponent,
    DashboardExpedienteComponent,
    FormExpedientePartesComponent,
    DialogExpedientesEnviadosComponent
  ]
})
export class ExpedienteModule { }
