import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { OrganismoDto } from '@models/organismo/organismoDto.model';
import { OrganismoService } from '@services/organismo/organismo.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogOrganismoGenericComponent } from '../dialog-organismo-generic/dialog-organismo-generic.component';
import { isFalsy } from 'utility-types';
import { AccionesDialog } from '@models/base/identificadores.model';
import { FormBuilder } from '@angular/forms';
import { LocalidadDto } from '@models/localidad/localidadDto.model';

@Component({
  selector: 'app-list-organismos',
  templateUrl: './list-organismos.component.html',
  styleUrls: ['./list-organismos.component.css']
})
export class ListOrganismosComponent {


  data = false
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<OrganismoDto>();
  formFilter: any;
  formBuilder: FormBuilder = new FormBuilder;
  localidadDto!: LocalidadDto;

  constructor(
    private organismoService: OrganismoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["nombre", "accion"] :
        ["nombre", "telefono", "correo", "localidad", "accion"];
    });

    this.formFilter = this.formBuilder.group({
      Nombre: [''],
    })
  }

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
  }

  filtrosAvanzados(event:any){}


  filtrar() {
    if (!isFalsy(this.localidadDto))
      this.getAllByIdLocalidad(this.localidadDto.id)
    else
      this.getAll();
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  seleccionaLocalidad(event: LocalidadDto) {
    this.localidadDto = event
  }

  getAllByIdLocalidad(idLocalidad: number) {
    this.spinner.show('spListado')
    this.organismoService.getAllByIdLocalidad(idLocalidad)
      .subscribe(
        data => {
          this.spinner.hide('spListado')
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide('spListado')
          this.snackBar.showError(error, "Error");
        })
  }

  getAll() {
    this.spinner.show('spListado')
    this.organismoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide('spListado')
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide('spListado')
          this.snackBar.showError(error, "Error");
        })
  }



  openDialog(action: any, obj: any) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%"
    dialogConfig.autoFocus = false;
    dialogConfig.data = obj
    obj.descripcion = isFalsy(obj.expediente) ? null : obj.expediente.caratula

    const dialogRef = this.dialog.open(DialogOrganismoGenericComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: { data: any; }) => {
      switch (result.data.action) {
        case AccionesDialog.Ver:
          break;
        default:
          this.getAllByIdLocalidad(this.localidadDto.id)
          break;
      }
    });
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
}
