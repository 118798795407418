<h1 mat-dialog-title>Enviar email a</h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <form [formGroup]="form">
        <mat-form-field appearance="outline"class="w-100" >
            <mat-label>Destinatarios</mat-label>
            <input cdkFocusInitial type="email" formControlName="Descripcion" matInput>
            <mat-error *ngIf="form.controls['Descripcion'].hasError('required')">Este campo es <strong>requerido</strong>.
            </mat-error>
            <mat-error *ngIf="form.controls['Descripcion'].hasError('email')">Correo invalido.</mat-error>
        </mat-form-field>
    </form>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="doAction()" [disabled]="!form.valid"><mat-icon matSuffix>send </mat-icon> <span>Enviar</span></button>
    <button mat-button (click)="closeDialog()" color="warn"><span><span>Cancelar</span></span></button>
</div>
