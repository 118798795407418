import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '@modules/shared.module';

@Component({
  selector: 'app-button-delete',
  standalone: true,
  imports: [SharedModule],
  template: `<button mat-stroked-button color="warn" [disabled]="disabled" name="Eliminar">
  <mat-icon>delete</mat-icon> Eliminar
</button>`,
  styleUrls: ['./button-delete.component.css'],
})
export class ButtonDeleteComponent {
  @Input() disabled! : any
}
