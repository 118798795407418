import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog, EstadosExpediente, EstadosExpedientePerito } from '@models/base/identificadores.model';
import { ExpedienteDto } from '@models/expediente/expedienteDto.model';
import { ExpedienteFilter } from '@models/expediente/expedienteFilter.model';
import { OrganismoDto } from '@models/organismo/organismoDto.model';
import { EstadoExpedienteService } from '@services/estadoExpediente/estadoExpediente.service';
import { EstadoExpedientePeritoService } from '@services/estadoExpedientePerito/estadoExpedientePerito.service';
import { ExpedienteService } from '@services/expediente/expediente.service';
import { OrganismoService } from '@services/organismo/organismo.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoExpedienteService } from '@services/tipoExpediente/tipoExpediente.service';
import { DialogDeleteComponent } from 'src/app/component/dialog-delete/dialog-delete.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogExpedientesEnviadosComponent } from '../dialog-expedientes-enviados/dialog-expedientes-enviados.component';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, map, startWith } from 'rxjs';
import { MatTableExporterDirective } from 'mat-table-exporter';

@Component({
  selector: 'app-list-expediente',
  templateUrl: './list-expediente.component.html',
  styleUrls: ['./list-expediente.component.css']
})
export class ListExpedienteComponent {


  @ViewChild(MatTableExporterDirective, { static: false })
  exporter!: MatTableExporterDirective;
  selection = new SelectionModel<ExpedienteDto>(true, []);
  data = false
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<ExpedienteDto>();
  isLoading: boolean = true;
  errorMessage: string = "";
  @Input() idCaso!: number
  @Input() idPersona!: number

  isChecked: boolean = false;

  filterValues = {
    caratula: '',
  };
  formBuilder: FormBuilder = new FormBuilder;
  formFilter: FormGroup;
  estadosExpediente!: BaseDto[];
  estadosExpedientePerito!: BaseDto[];
  checkedFiltroAvanzado = false;
  tiposExpediente!: BaseDto[];
  organismos: OrganismoDto[] = [];
  expedienteFilter!: ExpedienteFilter;
  constructor(
    private ExpedienteService: ExpedienteService,
    private organismoService: OrganismoService,
    private tipoExpedienteService: TipoExpedienteService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private EstadoExpedienteService: EstadoExpedienteService,
    private EstadoExpedientePeritoService: EstadoExpedientePeritoService,
    private dialog: MatDialog,
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["select", "caratula", "accion"] :
        ["select", "caratula", "cuij", "estadoExpediente", "estadoExpedientePerito", "clave", "fechaModificacion", "accion"];
    });


    this.formFilter = this.formBuilder.group({
      EstadoExpediente: [''],
      EstadoExpedientePerito: [''],
      Caratula: [''],
      Numero: [''],
      Cuij: [''],
      Organismo: [''],
      TipoExpediente: [''],
      ClaveSisfe: [''],
    })

  }

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
    this.getAllOrganismos();
    const expedientes = this.ExpedienteService.getExpedientes();
    if (expedientes)
      if (expedientes.length >= 1) {
        this.data = true
        this.dataSource.data = expedientes
      }

    if (this.idCaso)
      this.getAllExpedienteByCaso(this.idCaso)

    if (this.idPersona)
      this.getAllExpedienteByIdPersona(this.idPersona)
  }


  filtrar() {
    this.expedienteFilter = new ExpedienteFilter

    this.expedienteFilter.caratula = isFalsy(this.formFilter.controls['Caratula'].value) ? null : this.formFilter.controls['Caratula'].value;

    this.expedienteFilter.cuij = isFalsy(this.formFilter.controls['Cuij'].value) ? null : this.formFilter.controls['Cuij'].value;

    this.expedienteFilter.numero = isFalsy(this.formFilter.controls['Numero'].value) ? null : this.formFilter.controls['Numero'].value;

    this.expedienteFilter.idTipoExpediente = isFalsy(this.formFilter.controls['TipoExpediente'].value) ? null : this.formFilter.controls['TipoExpediente'].value;

    this.expedienteFilter.idsEstadoExpedientePerito = isFalsy(this.formFilter.controls['EstadoExpedientePerito'].value) ? null : this.formFilter.controls['EstadoExpedientePerito'].value;

    this.expedienteFilter.idsEstadoExpediente = isFalsy(this.formFilter.controls['EstadoExpediente'].value) ? null : this.formFilter.controls['EstadoExpediente'].value;

    this.expedienteFilter.idOrganismo = isFalsy(this.formFilter.controls['Organismo'].value) ? null : this.formFilter.controls['Organismo'].value;

    this.expedienteFilter.sinClave = isFalsy(this.formFilter.controls['ClaveSisfe'].value) ? null : this.formFilter.controls['ClaveSisfe'].value;

    this.getAllExpedientesByFilter(this.expedienteFilter)
  }

  filtrosAvanzados(event: any) {
    if (event.checked == true)
      this.checkedFiltroAvanzado = true
    else
      this.checkedFiltroAvanzado = false

    this.getAllTiposExpediente();
    this.getAllOrganismos();
    this.getAllEstadosExpedientePerito();
    this.getAllEstadosExpediente();

  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllExpedientesByFilter(expedienteFilter: ExpedienteFilter) {
    this.spinner.show('spExpediente')
    this.ExpedienteService.getAllExpedientesByFilter(expedienteFilter)
      .subscribe(
        data => {
          this.spinner.hide('spExpediente')
          this.dataSource.data = data.sort((a, b) => a.caratula.toString().localeCompare(b.caratula.toString())) as ExpedienteDto[]
          this.data = this.dataSource.data.length >= 1 ? true : false

          this.ExpedienteService.setExpedientes(this.dataSource.data)

        },
        error => {
          this.spinner.hide('spExpediente')
          this.snackBar.showError(error, "Error");
        })
  }

  getAllExpedienteByCaso(idCaso: number) {
    this.spinner.show('spExpediente')
    this.ExpedienteService.getAllByCaso(idCaso)
      .subscribe(
        data => {
          this.spinner.hide('spExpediente')
          this.dataSource.data = data.sort((a, b) => a.caratula.toString().localeCompare(b.caratula.toString())) as ExpedienteDto[]
          this.data = this.dataSource.data.length >= 1 ? true : false

          let estadoExpediente = this.formFilter.controls["EstadoExpediente"].value
          let estadoExpedientePerito = this.formFilter.controls["EstadoExpedientePerito"].value



          if (estadoExpediente >= 1)
            this.dataSource.data = this.dataSource.data.filter(x => x.estadoExpediente.id == estadoExpediente)

          if (estadoExpedientePerito >= 1)
            this.dataSource.data = this.dataSource.data.filter(x => x.estadoExpedientePerito.id == estadoExpedientePerito)

          this.ExpedienteService.setExpedientes(this.dataSource.data)
        },
        error => {
          this.spinner.hide('spExpediente')
          this.snackBar.showError(error, "Error");
        })
  }

  getAllExpedienteByIdPersona(idPersona: number) {
    this.spinner.show('spExpediente')
    this.expedienteFilter.idPersona = idPersona
    this.ExpedienteService.getAllExpedientesByFilter(this.expedienteFilter)
      .subscribe(
        data => {
          this.spinner.hide('spExpediente')
          this.dataSource.data = data.sort((a, b) => a.caratula.toString().localeCompare(b.caratula.toString())) as ExpedienteDto[]
          this.data = this.dataSource.data.length >= 1 ? true : false

          let estadoExpediente = this.formFilter.controls["EstadoExpediente"].value
          let estadoExpedientePerito = this.formFilter.controls["EstadoExpedientePerito"].value



          if (estadoExpediente >= 1)
            this.dataSource.data = this.dataSource.data.filter(x => x.estadoExpediente.id == estadoExpediente)

          if (estadoExpedientePerito >= 1)
            this.dataSource.data = this.dataSource.data.filter(x => x.estadoExpedientePerito.id == estadoExpedientePerito)

          this.ExpedienteService.setExpedientes(this.dataSource.data)
        },
        error => {
          this.spinner.hide('spExpediente')
          this.snackBar.showError(error, "Error");
        })
  }

  selectValue(event: any) {
    if (!isFalsy(event)) {
      this.expedienteFilter.idOrganismo = event.id;
    }
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }

  deleteRowData(id: number) {
    this.spinner.show('spExpediente')
    this.ExpedienteService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide('spExpediente')
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          this.getAllExpedientesByFilter(this.expedienteFilter);
        },
        error => {
          this.spinner.hide('spExpediente')
          this.snackBar.showError(error, "Error");
        });
  }

  getAllEstadosExpedientePerito() {
    this.spinner.show("spBusquedaEstadoExpedientePerito")
    this.EstadoExpedientePeritoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaEstadoExpedientePerito")
          let todos = new BaseDto
          todos.id = 0
          todos.descripcion = "Todos"
          data.push(todos)
          this.estadosExpedientePerito = data
        },
        error => {
          this.spinner.hide("spBusquedaEstadoExpedientePerito")
          this.snackBar.showError(error, "Error");
        })
  }
  getAllEstadosExpediente() {
    this.spinner.show("spBusquedaEstadoExpediente")
    this.EstadoExpedienteService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaEstadoExpediente")
          let todos = new BaseDto
          todos.id = 0
          todos.descripcion = "Todos"
          data.push(todos)
          this.estadosExpediente = data
        },
        error => {
          this.spinner.hide("spBusquedaEstadoExpediente")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllTiposExpediente() {
    this.spinner.show("spBusquedaTipoExpediente")
    this.tipoExpedienteService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTipoExpediente")
          let todos = new BaseDto
          todos.id = 0
          todos.descripcion = "Todos"
          data.push(todos)
          this.tiposExpediente = data
        },
        error => {
          this.spinner.hide("spBusquedaTipoExpediente")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllOrganismos() {
    this.spinner.show("spBusquedaOrganismos")
    this.organismoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaOrganismos")
          let todos = new OrganismoDto
          todos.id = 0
          todos.descripcion = "Todos"
          data.push(todos)
          this.organismos = data
        },
        error => {
          this.spinner.hide("spBusquedaOrganismos")
          this.snackBar.showError(error, "Error");
        })
  }

  exportarTabla() {
    this.exporter.exportTable('xlsx', {fileName:'Archivo_Exportado', sheet: 'Hoja1', Props: {Author: 'GPerito'}})
  }
  solicitarClavePorCorreo() {
    let idsExpedientesFiltrar: number[] = []
    this.selection.selected.forEach(element => {
      idsExpedientesFiltrar.push(element.id)
    });

    this.spinner.show("spExpediente")
    this.ExpedienteService.solicitarClavePorCorreoAJuzgado(idsExpedientesFiltrar)
      .subscribe(
        data => {
          this.spinner.hide("spExpediente")

          const dialogConfig = new MatDialogConfig();
          dialogConfig.width = "90%"
          dialogConfig.autoFocus = false;
          dialogConfig.data = data

          const dialogRef = this.dialog.open(DialogExpedientesEnviadosComponent, dialogConfig);

        },
        error => {
          this.spinner.hide("spExpediente")
          this.snackBar.showError(error, "Error");
        })

  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ExpedienteDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  trackByItems(index: number, item: any): any { return item; }
}
