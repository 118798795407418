import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CreateNotificacionRequest } from '@models/notificacion/createNotificacionRequest.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { UpdateNotificacionRequest } from '@models/notificacion/updateNotificacionRequest.model';
import { isFalsy } from 'utility-types';
import { NotificacionDto } from '@models/notificacion/notificacionDto.model';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  constructor(private http:HttpClient) {
  }

  create(createNotificacionRequest:CreateNotificacionRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/Notificacion/Create",createNotificacionRequest);
  }

  update(updateNotificacionRequest:UpdateNotificacionRequest):Observable<any>
  {
    return  this.http.put<any>("/api/Notificacion/Update",updateNotificacionRequest);
  }

  getAll():Observable<NotificacionDto[]> {
    return   this.http.get<NotificacionDto[]>("/api/Notificacion/GetAll");
  }


  getById(id:number):Observable<NotificacionDto>{
    return  this.http.get<NotificacionDto>("/api/Notificacion/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Notificacion/Delete?id=" + id);
    else
      return of(null)
  }
}
