import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-sin-datos-para-mostrar',
  templateUrl: './sin-datos-para-mostrar.component.html',
  styleUrls: ['./sin-datos-para-mostrar.component.css'],
})
export class SinDatosParaMostrarComponent {

  @Input() dataSource! :any
  @Input() data! :any
  constructor() { }

  ngOnInit(): void {
  }
}

