import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { Menu, SubMenu } from '@models/seguridad/menu/menu.model';
import { PermisoDto } from '@models/seguridad/permisos/permisoDto.model';
import { UsuarioDto } from '@models/usuario/usuarioDto.model';
import { LoginService } from '@services/login/login.service';
import Config from 'chart.js/dist/core/core.config';
import { isFalsy } from 'utility-types';

export type Menu2 = {
  name: string;
  iconClass: string;
  active: boolean;
  submenu: { name: string; url: string }[];
};
@Component({
  selector: 'app-menu-principal',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})

//https://stackblitz.com/edit/angular-accordion-menu-mw1qe7?file=src%2Fapp%2Faccordion%2Ftypes%2FMenu.ts
export class MenuPrincipalComponent {
  archivo: ArchivoDto = new ArchivoDto();
  @ViewChild('inputSearch') input?: ElementRef;
  hidden = false;
  isLoading: boolean = true;
  matTooltip?: string;
  menues!: Menu[];
  value = 'Buscar';
  user!: UsuarioDto;
  selectedRoute: string = '';
  toggleHamburger: boolean = true;
  toggledMenus: { [menuName: string]: boolean } = {};
  openSubmenu: string | null = null;
  submenuIndex: number | null = null;
  activeIndex: number | null = null;
  @Output() toggleDrawerEvent = new EventEmitter<void>();
  // @ViewChild('drawer', { static: false }) drawer!: MatDrawer;
  constructor(private loginService: LoginService, private router: Router) {}

  ngOnInit(): void {
    this.user = this.loginService.getCurrentUser();
    this.archivo.id = 1;
    if (this.user)
      this.matTooltip = `Nombre y Apellido: &#13s; ${
        this.user.nombre + ' ' + this.user.apellido
      }. &#13; Usuario: &#13; ${this.user.userName}`;

    if (this.user.rol.permisos)
      this.user.rol.permisos.forEach((element) => {
        if (isFalsy(this.menues)) this.menues = [];

        let menu = isFalsy(element.casoUso.menu)
          ? undefined
          : (this.menues.find(
              (x) => x.displayName === element.casoUso.menu.descripcion
            ) as Menu);

        if (isFalsy(menu)) {
          menu = this.addMenu(element);
          if (!isFalsy(menu)) {
            if (
              menu.submenues.find(
                (x) => x.displayName === element.casoUso.descripcion
              )
            ) {
              let subMenu = menu.submenues.find(
                (x) => x.displayName === element.casoUso.descripcion
              ) as SubMenu;
              subMenu = this.addSubMenu(element);
              menu.submenues.push(subMenu);
            } else {
              let subMenu = this.addSubMenu(element);
              menu.submenues.push(subMenu);
            }
            this.menues.push(menu);
          }
        } else {
          if (
            menu.submenues.find(
              (x) => x.displayName != element.casoUso.descripcion
            )
          ) {
            let subMenu = menu.submenues.find(
              (x) => x.displayName === element.casoUso.descripcion
            ) as SubMenu;
            if (subMenu == undefined) {
              let subMenu = this.addSubMenu(element);
              menu.submenues.push(subMenu);
            }
          }
        }
      });

    this.isLoading = false;
  }
  toggleDrawer() {
    this.activeIndex = null;
    this.toggleDrawerEvent.emit();
  }

  isSelected(item: any): boolean {
    return this.selectedRoute === item.route;
  }

  // Llama a esta función cuando se haga clic en un elemento
  onMenuClick(item: any): void {
    this.selectedRoute = item.route;
  }

  addMenu(element: PermisoDto) {
    let menu!: Menu;
    if (element.casoUso.menu) {
      menu = new Menu();
      menu.displayName = isFalsy(element.casoUso.menu.descripcion)
        ? ''
        : element.casoUso.menu.descripcion;
      menu.iconName = isFalsy(element.casoUso.menu.icon)
        ? ''
        : element.casoUso.menu.icon;
      menu.route = isFalsy(element.casoUso.menu.path)
        ? ''
        : element.casoUso.menu.path;
    }
    return menu;
  }
  addSubMenu(element: PermisoDto) {
    let subMenu!: SubMenu;
    if (element.casoUso) {
      subMenu = new SubMenu();
      subMenu.displayName = isFalsy(element.casoUso.descripcion)
        ? ''
        : element.casoUso.descripcion;
      subMenu.iconName = isFalsy(element.casoUso.icon)
        ? ''
        : element.casoUso.icon;
      subMenu.route = isFalsy(element.casoUso.path) ? '' : element.casoUso.path;
    }
    return subMenu;
  }

  //

  toggleCategory(index: number): void {
    if (index !== this.activeIndex) {
      setTimeout(() => {
        this.activeIndex = this.activeIndex === index ? null : index; // Alternar la categoría activa
      }, 1);
    }
  }

  trackByItems(index: number, item: any): number {
    return index;
  }
}
