<app-go-back-and-title titulo="Nuevo Caso"></app-go-back-and-title>

  <form [formGroup]="casoForm" *appRole="['Casos.Crear']">
    <mat-card style="margin-bottom: 20px">
      <mat-card-content>
        <div class="row">
          <div class="col">
            <div class="col">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Caratula</mat-label>
                <input matInput type="text" maxlength="200" style="text-transform: uppercase"
                  formControlName="Caratula" />
                <mat-error *ngIf="casoForm.controls['Caratula'].hasError('required')">Este campo es
                  <strong>requerido</strong>.</mat-error>
                <mat-error *ngIf="casoForm.controls['Caratula'].hasError('maxlength')">Supero el largo
                  maximo.</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Fecha Comienzo</mat-label>
              <input matInput [matDatepicker]="pickerFechaComienzo" formControlName="FechaComienzo"
                placeholder="MM/DD/YYYY" />
              <mat-datepicker-toggle matIconSuffix [for]="pickerFechaComienzo"></mat-datepicker-toggle>
              <mat-datepicker #pickerFechaComienzo></mat-datepicker>
              <mat-error *ngIf="casoForm.controls['FechaComienzo'].hasError('required')">Este campo es
                <strong>requerido</strong>.</mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Fecha Finalización</mat-label>
              <input matInput [matDatepicker]="pickerFechaFinalizacion" formControlName="FechaFinalizacion"
                placeholder="MM/DD/YYYY" />
              <mat-datepicker-toggle matIconSuffix [for]="pickerFechaFinalizacion"></mat-datepicker-toggle>
              <mat-datepicker #pickerFechaFinalizacion></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <ngx-spinner [zIndex]="-1" name="spTiposCaso" bdColor="rgba(255,255,255,0.8)" size="small"
                color=" #009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
              <mat-label>Tipo Caso</mat-label>
              <mat-select placeholder="Seleccione opcion" formControlName="TipoCaso">
                <mat-option [value]="tipoCaso.id" *ngFor="
              let tipoCaso of tiposCaso;
              let i = index;
              trackBy: trackByItems
            ">
                  {{ tipoCaso.descripcion }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <ngx-spinner [zIndex]="-1" name="spEstadosCaso" bdColor="rgba(255,255,255,0.8)" size="small"
                color=" #009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
              <mat-label>Estado Caso</mat-label>
              <mat-select placeholder="Seleccione opcion" formControlName="EstadoCaso">
                <mat-option [value]="estadoCaso.id" *ngFor="
              let estadoCaso of estadosCaso;
              let i = index;
              trackBy: trackByItems
            ">
                  {{ estadoCaso.descripcion }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <mat-form-field appearance="outline" class="w-100 col-lg-12">
            <mat-label>Descripcion</mat-label>
            <textarea matInput #message name="Descripcion" formControlName="Descripcion"></textarea>
            <mat-error *ngIf="casoForm.controls['Descripcion'].hasError('maxlength')">Supero el largo
              maximo.</mat-error>
            <mat-hint align="start">Amplie información</mat-hint>
            <mat-hint align="end">{{ message.value.length }} / 2000</mat-hint>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <app-button-add [disabled]="!casoForm.valid" (click)="onSubmit()"></app-button-add>
  </form>
