import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private history: string[] = [];

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.history.push(event.url);
      }
    });
  }

  public back(): void {
    this.history.pop();
    const previousUrl = this.history.pop();
    if (previousUrl) { this.router.navigateByUrl(previousUrl); } else { this.router.navigate(['/']); }
  }
}
