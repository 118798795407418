import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '@modules/shared.module';

@Component({
  selector: 'app-button-new',
  standalone: true,
  imports: [SharedModule],
  template: `<button mat-raised-button color="primary" [disabled]="disabled" name="Nuevo">
  <mat-icon>add</mat-icon> Nuevo
</button>`,
  styleUrls: ['./button-new.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonNewComponent {
   @Input() disabled : boolean = false
}
