import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedModule } from '@modules/shared.module';
import { NavigationService } from '@services/navigation-service/navigation-service.service';

@Component({
  selector: 'app-button-goback',
  standalone: true,
  imports: [SharedModule],
  template: `<button  (click)="goBack()"  color="primary" mat-button> <mat-icon>arrow_back</mat-icon>Volver</button>`,
  styleUrls: ['./button-goback.component.css'],
})

export class ButtonGobackComponent {
  constructor(private navigationService: NavigationService,
      private route: ActivatedRoute, private router: Router
    ) { }
    goBack(): void { this.navigationService.back(); }
}
