<mat-card *appRole="['Personas.Modificar']">
  <mat-card-header>
    <mat-card-title>
      Editar persona
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="personaForm">
      <ngx-spinner
        [zIndex]="100"
        name="spPersona"
        bdColor="rgba(255,255,255,0.8)"
        size="default"
        color=" #009688"
        type="ball-atom"
        [fullScreen]="false"
      >
      </ngx-spinner>
      <div class="row">
        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Nombre</mat-label>
            <input
              matInput
              type="text"
              maxlength="50"
              style="text-transform: capitalize"
              formControlName="Nombre"
            />
            <mat-error
              *ngIf="personaForm.controls['Nombre'].hasError('required')"
              >Este campo es <strong>requerido</strong>.</mat-error
            >
            <mat-error
              *ngIf="personaForm.controls['Nombre'].hasError('maxlength')"
              >Supero el largo maximo.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Apellido</mat-label>
            <input
              matInput
              style="text-transform: capitalize"
              maxlength="50"
              type="text"
              formControlName="Apellido"
            />
            <mat-error
              *ngIf="personaForm.controls['Apellido'].hasError('maxlength')"
              >Supero el largo maximo.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Sexo</mat-label>
            <mat-select formControlName="Sexo">
              <mat-option value="M">Masculino</mat-option>
              <mat-option value="F">Femenino</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Tipo Documento</mat-label>
            <mat-select
              #SelectTipoDocuemtno
              placeholder="Seleccione opcion"
              formControlName="TiposDocumento"
              (selectionChange)="selectTipoDocumento($event)"
            >
              <mat-option
                [value]="tipoDocumento.id"
                *ngFor="
                  let tipoDocumento of lstTipoDocumentoDto;
                  let i = index;
                  trackBy: trackByItems
                "
              >
                {{ tipoDocumento.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Nro. Documento</mat-label>
            <input
              matInput
              type="text"
              maxlength="15"
              #NroDocumento
              oninput="this.value=this.value.toUpperCase()"
              formControlName="NroDocumento"
            />
            <mat-error
              *ngIf="
                personaForm.controls['NroDocumento'].hasError('maxlength')
              "
              >Supero el largo maximo.</mat-error
            >
            <mat-error
              *ngIf="
                personaForm.controls['NroDocumento'].hasError('minlength')
              "
              >Debe supererar el campo minim.</mat-error
            >
            <mat-error
              *ngIf="personaForm.controls['NroDocumento'].errors?.['existePersona']"
              >Esta persona ya existe..</mat-error
            >
            <mat-error
              *ngIf="personaForm.controls['NroDocumento'].errors?.['pattern']"
              >Caracteres no validos.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Fecha Nacimiento</mat-label>
            <input
              matInput
              name="FechaNacimiento"
              type="date"
              formControlName="FechaNacimiento"
            />
          </mat-form-field>
        </div>
        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Correo</mat-label>
            <input matInput type="email" formControlName="Correo" />
            <mat-error
              *ngIf="personaForm.controls['Correo'].hasError('required')"
              >Este campo es <strong>requerido</strong>.</mat-error
            >
            <mat-error
              *ngIf="personaForm.controls['Correo'].hasError('email')"
              >Formato invalido.</mat-error
            >
          </mat-form-field>
        </div>

        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Estado Civil</mat-label>
            <mat-select #SelectEstadoCivil formControlName="EstadoCivil">
              <mat-option
                [value]="estadoCivil.id"
                *ngFor="
                  let estadoCivil of lstEstadosCivilModel;
                  let i = index;
                  trackBy: trackByItems
                "
              >
                {{ estadoCivil.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Telefono Personal</mat-label>
            <!-- <span matPrefix>+54 &nbsp;</span> -->
            <input
              matInput
              maxlength="20"
              placeholder="Sin 0 y sin 15"
              type="number"
              formControlName="TelefonoPrincipal"
            />

            <mat-error
              *ngIf="
                personaForm.controls['TelefonoPrincipal'].hasError(
                  'required'
                )
              "
              >Este campo es <strong>requerido</strong>.</mat-error
            >
            <mat-error
              *ngIf="
                personaForm.controls['TelefonoPrincipal'].hasError(
                  'maxlength'
                )
              "
              >Supero el largo maximo.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Telefono Particular</mat-label>
            <!-- <span matPrefix>+54 &nbsp;</span> -->
            <input
              matInput
              name="TelefonoAlternativo"
              maxlength="20"
              placeholder="Sin 0 y sin 15"
              type="number"
              formControlName="TelefonoAlternativo"
            />
            <mat-error
              *ngIf="
                personaForm.controls['TelefonoAlternativo'].hasError(
                  'maxlength'
                )
              "
              >Supero el largo maximo.</mat-error
            >
          </mat-form-field>
        </div>

        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Ocupación</mat-label>
            <input
              matInput
              name="Ocupacion"
              type="text"
              maxlength="50"
              formControlName="Ocupacion"
            />
            <mat-error
              *ngIf="
                personaForm.controls['Ocupacion'].hasError('maxlength')
              "
              >Supero el largo maximo.</mat-error
            >
          </mat-form-field>
        </div>

        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Ubicación</mat-label>
            <input
              matInput
              name="Ubicacion"
              style="text-transform: capitalize"
              type="text"
              maxlength="50"
              formControlName="Ubicacion"
            />
            <mat-error
              *ngIf="
                personaForm.controls['Ubicacion'].hasError('maxlength')
              "
              >Este campo es <strong>requerido</strong>.</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <app-buscar-localidad
            [localidad]="personaDetailDto.localidad"
            (eventoLocalidad)="seleccionaLocalidad($event)"
          ></app-buscar-localidad>
        </div>
        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Codigo Postal</mat-label>
            <input
              matInput
              name="CodigoPostal"
              style="text-transform: capitalize"
              type="text"
              maxlength="50"
              formControlName="CodigoPostal"
            />
          </mat-form-field>
        </div>
        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Nacionalidad</mat-label>
            <mat-select formControlName="Pais">
              <mat-option
                [value]="pais.id"
                *ngFor="
                  let pais of lstPaises;
                  let i = index;
                  trackBy: trackByItems
                "
              >
                {{ pais.descripcion }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Telefono Laboral</mat-label>
            <!-- <span matPrefix>+54 &nbsp;</span> -->
            <input
              matInput
              maxlength="20"
              placeholder="Sin 0 y sin 15"
              type="tel"
              formControlName="TelefonoLaboral"
            />
            <mat-error
              *ngIf="
                personaForm.controls['TelefonoLaboral'].hasError(
                  'maxlength'
                )
              "
              >Supero el largo maximo.</mat-error
            >
          </mat-form-field>
        </div>

        <div class="form-group col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Ubicacion Laboral</mat-label>
            <input
              matInput
              name="UbicacionLaboral"
              style="text-transform: capitalize"
              type="text"
              maxlength="50"
              formControlName="UbicacionLaboral"
            />
            <mat-error
              *ngIf="
                personaForm.controls['UbicacionLaboral'].hasError(
                  'maxlength'
                )
              "
              >Supero el largo maximo.</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Observaciones</mat-label>
            <textarea
              matInput
              #message
              name="Observacion"
              formControlName="Observacion"
            ></textarea>
            <!-- <mat-error *ngIf="f.Observacion.errors?.maxlength ">Supero el maximo de caracteres.</mat-error>
<mat-hint align="start "><strong>Amplie información personal</strong> </mat-hint>
<mat-hint align="end ">{{message.value.length}} / 2000</mat-hint> -->
          </mat-form-field>
        </div>
      </div>
      <br />
    </form>
  </mat-card-content>
  <mat-card-actions align="end">
    <button
      mat-raised-button
      *appRole="['Personas.Modificar']"
      color="primary"
      style="margin-right: 3px"
      name="Guardar"
      [disabled]="!personaForm.valid"
      (click)="onSubmit()"
    >
      Guardar
    </button>
  </mat-card-actions>
</mat-card>
