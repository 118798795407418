import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { LocalidadModule } from "src/app/modules/localidad.module";
import { SharedModule } from "src/app/modules/shared.module";
import { PersonaService } from "@services/persona/persona.service";
import { BuscarPersonaComponent } from "@views/busquedas/buscar-persona/buscar-persona.component";
import { BtnDeletePersonaComponent } from "@views/personas/btn-delete-persona/btn-delete-persona.component";
import { BtnNuevaPersonaComponent } from "@views/personas/btn-nueva-persona/btn-nueva-persona.component";
import { DialogDeletePersonaComponent } from "@views/personas/dialog-delete-persona/dialog-delete-persona.component";
import { FormPersonaCreateComponent } from "@views/personas/form-persona-create/form-persona-create.component";
import { FormPersonaReactiveComponent } from "@views/personas/form-persona-reactive/form-persona-reactive.component";
import { FormPersonaUpdateComponent } from "@views/personas/form-persona-update/form-persona-update.component";
import { ListadoPersonasComponent } from "@views/personas/listado-personas/listado-personas.component";
import { PersonasComponent } from "@views/personas/personas/personas.component";
import { DialogCreatePersonaSimpleComponent } from "@views/personas/dialog-create-persona-simple/dialog-create-persona-simple.component";
import { DialogViewBasicPersonaComponent } from "@views/personas/dialog-view-basic-persona/dialog-view-basic-persona.component";
import { FormPersonaViewComponent } from "@views/personas/form-persona-view/form-persona-view/form-persona-view.component";
import { ContactoModule } from "./contacto.module";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LocalidadModule,
    ContactoModule,
  ],
  declarations: [
    PersonasComponent,
    ListadoPersonasComponent,
    BuscarPersonaComponent,
    BtnNuevaPersonaComponent,
    BtnDeletePersonaComponent,
    FormPersonaCreateComponent,
    FormPersonaUpdateComponent,
    DialogDeletePersonaComponent,
    BuscarPersonaComponent,
    FormPersonaReactiveComponent,
    DialogCreatePersonaSimpleComponent,
    DialogViewBasicPersonaComponent,
    FormPersonaViewComponent
  ],
  providers: [
    PersonaService
  ],
  exports: [
    PersonasComponent,
    ListadoPersonasComponent,
    BuscarPersonaComponent,
    BtnNuevaPersonaComponent,
    BtnDeletePersonaComponent,
    FormPersonaCreateComponent,
    FormPersonaUpdateComponent,
    DialogDeletePersonaComponent,
    BuscarPersonaComponent,
    FormPersonaReactiveComponent,
    DialogCreatePersonaSimpleComponent,
    DialogViewBasicPersonaComponent,
    FormPersonaViewComponent
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})

export class PersonaModule { }
