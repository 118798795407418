<div class="container-fluid vh-100 d-flex justify-content-center align-items-center bg-light">
  <div class="row shadow-lg rounded-lg overflow-hidden" style="max-width: 500px; width: 100%;">

      <!-- Contenedor del formulario -->
      <div class="col-12 bg-white p-5">
          <div class="text-center mb-4">
              <h3 class="fw-bold" *ngIf="entorno != 'Produccion'">
                  <span style="color: red;">GPerito Entorno:</span> {{entorno}}
              </h3>
              <h3 *ngIf="modoRecuperacion" class="fw-bold">Recupera tu cuenta</h3>
          </div>

          <!-- Sección de Recuperación -->
          <div *ngIf="modoRecuperacion">
            <app-recover-password></app-recover-password>
            <button class="btn btn-link text-decoration-none mt-3" (click)="goToLogin()">Volver a Iniciar Sesión</button>
        </div>

        <!-- Sección de Inicio de Sesión -->
        <div *ngIf="!modoRecuperacion">
            <app-iniciar-sesion></app-iniciar-sesion>
            <button class="btn btn-link text-decoration-none mt-3" (click)="RecuperarCuenta()">Recuperar contraseña</button>
        </div>
      </div>
  </div>
</div>
