import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginRequest } from '@models/login/login-model.model copy';
import { TokenModel } from '@models/login/token-model.model';
import { LoginService } from '@services/login/login.service';
import { UsuarioDto } from '@models/usuario/usuarioDto.model'
import { Router } from '@angular/router';
import { UsuarioService } from '@services/usuario/usuario.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { PersonaDetailDto } from '@models/persona/personaDetailDto.model';

@Component({
  selector: 'app-iniciar-sesion',
  templateUrl: './iniciar-sesion.component.html',
  styleUrls: ['./iniciar-sesion.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class IniciarSesionComponent implements OnInit {
  submitted = false;
  loginForm: FormGroup;
  tokenModel: TokenModel = new TokenModel
  loginRequest: LoginRequest = new LoginRequest
  public personaDetailDto: PersonaDetailDto = new PersonaDetailDto;
  UsuarioDto: UsuarioDto = new UsuarioDto;
  idPersona: number = 0;
  isLoading: boolean = false;
  loginTrue: boolean = false;
  formBuilder: FormBuilder = new FormBuilder;
  get f() { return this.loginForm.controls; }
  modoRecuperacion = false;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private userService: UsuarioService,
    private snackBar: SnackBarService
  ) {

    this.loginForm = this.formBuilder.group({
      Usuario: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      Clave: ['', Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {
  }

  loguearse() {
    this.isLoading = true;
    this.submitted = true;

    if (this.loginForm.invalid) {
      this.snackBar.showWarn("Formulario invalido.", "")
      return;
    }

    if (this.loginForm.valid == true) {
      this.submitted = true;
      this.loginRequest.userName = this.loginForm.controls["Usuario"].value
      this.loginRequest.password = btoa(this.loginForm.controls["Clave"].value)//Encripta en base64

      this.loginService.login(this.loginRequest)
        .subscribe(
          tokenModel => {
            this.isLoading = false
            this.loginTrue = true
            this.recibirToken(tokenModel)
            this.obtenerUsuario(this.loginRequest.userName)
          },
          error => {
            this.isLoading = false
            this.snackBar.showError(error, "")
          }
        )

    }

  }

  obtenerUsuario(userName: string) {
    this.isLoading = true
    this.userService.getAllDataOfUserByUserName(userName).subscribe(
      data => {
        this.UsuarioDto = data

        if (this.UsuarioDto.activo == false || this.UsuarioDto.id == 0) {
          this.loginService.logout()
          this.router.navigateByUrl("/login")
          this.snackBar.showWarn("Usuario dado de baja.", "")
          this.isLoading = false
        }
        else {
          this.loginService.setCurrentUser(data)
          if (this.UsuarioDto.changePassword == true) {
            this.snackBar.showInfo("Asegurese de cambiar su contraseña.", "")
          }
          this.router.navigateByUrl("/dashboard");
          this.isLoading = false
        }
      },
      error => {
        this.snackBar.showError(error, "Error");
      })
  }
  recibirToken(tokenModel: TokenModel) {
    if (tokenModel != null) {
      this.loginService.setToken(tokenModel);
    }
  }
}

