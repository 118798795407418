<div class="position-relative">
  <form [formGroup]="localidadForm">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <mat-form-field appearance="outline" class="w-100">
          <ngx-spinner
            [zIndex]="-1"
            name="spProvincia"
            bdColor="rgba(255,255,255,0.8)"
            size="small"
            color=" #009688"
            type="line-scale"
            [fullScreen]="false"
          ></ngx-spinner>
          <mat-icon matSuffix>search</mat-icon>
          <input
            matInput
            placeholder="Provincia"
            formControlName="Provincia"
            [matAutocomplete]="autoPronvicia"
          />

          <!-- <mat-error *ngIf="localidadForm.controls['Provincia'].hasError('required')">Este campo es <strong>requerido</strong>.</mat-error> -->
          <mat-hint>Habilita localidades.</mat-hint>
        </mat-form-field>
        <mat-autocomplete #autoPronvicia="matAutocomplete">
          <mat-option
            *ngFor="
              let provincia of filteredProvincia | async;
              let i = index;
              trackBy: trackByItems
            "
            [value]="provincia.descripcion"
            (click)="getLocalidadesByProvincia(provincia.id)"
            (onSelectionChange)="getLocalidadesByProvincia(provincia.id)"
          >
            <div>{{ provincia.descripcion | titlecase }}</div>
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <mat-form-field appearance="outline" class="w-100 w-100">
          <ngx-spinner
            [zIndex]="-1"
            name="spLocalidades"
            bdColor="rgba(255,255,255,0.8)"
            size="small"
            color=" #009688"
            type="line-scale"
            [fullScreen]="false"
          >
          </ngx-spinner>
          <mat-icon matSuffix>search</mat-icon>
          <input
            matInput
            typehead
            placeholder="Localidad"
            formControlName="Localidad"
            [matAutocomplete]="autoLocalidad"
          />
          <mat-error
            *ngIf="localidadForm.controls['Localidad'].hasError('required')"
            >Este campo es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
        <mat-autocomplete #autoLocalidad="matAutocomplete">
          <mat-option
            *ngFor="
              let item of filteredLocalidades | async;
              let i = index;
              trackBy: trackByItems
            "
            [value]="item.descripcion"
            (click)="selectValue(item)"
            (onSelectionChange)="selectValue(item)"
          >
            <div>{{ item.descripcion | titlecase }}</div>
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>
  </form>
</div>
