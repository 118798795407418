<button
  mat-stroked-button
  color="primary"
  [matMenuTriggerFor]="matMenuTriggerFor"
  class="app-button-action"
  matTooltip="Acciones masivas"
>
  <mat-icon >more_vert</mat-icon>Acciones
</button>


<mat-menu #matMenuTriggerFor="matMenu">
  <ng-container *ngFor="let action of actions">
    <button
      color='primary'
      mat-menu-item
      (click)="action.handler()"
      *appRole="action.roles"
    >
      <mat-icon>{{ action.icon }}</mat-icon>
      <span>{{ action.label }}</span>
    </button>
  </ng-container>
</mat-menu>
