<h1>Hola</h1>
<!-- <div class="row" *appRole="data.roles">
  <app-button-close-dialog></app-button-close-dialog>
  <h1 mat-dialog-title>{{data.titulo}}</h1>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-md-12">
        <ng-container *ngFor="let dato of data.datos1">
          <div class="property-row">
            <span class="property-name">{{dato.nombre}}:</span>
            <span class="property-value">{{ dato.valor }}
            </span>
          </div>
        </ng-container>
      </div>
      <div class="col-md-12">
        <ng-container *ngFor="let dato of data.datos2">
          <div class="property-row">
            <span class="property-name">{{dato.nombre}}:</span>
            <span class="property-value">{{ dato.valor  }}</span>

          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <app-button-close mat-dialog-close></app-button-close>
  </div>
</div> -->

<!-- <span class="property-value">{{ dato.valor | dynamicPipe: dato['propertyPipe'] }}</span> -->
