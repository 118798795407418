<!-- Breadcrumb -->
<div class="xng-breadcrumb">
  <xng-breadcrumb class="xng-breadcrumb-root" [separator]="iconTemplate">
    <ng-container
      *xngBreadcrumbItem="
        let data;
        let breadcrumb;
        let info = info;
        let label = label;
        let first = first
      "
    >
      <!-- Icono al principio del breadcrumb -->
      <mat-icon *ngIf="info" class="breadcrumb-icon">{{ info }}</mat-icon>

      <!-- Breadcrumb normal -->
      <ng-container *ngIf="!first">
        <span class="xng-breadcrumb-trail">{{ breadcrumb }}</span>
      </ng-container>

      <!-- Breadcrumb de inicio (home) -->
      <ng-container *ngIf="first">
        <span class="xng-breadcrumb-trail">
          <mat-icon class="breadcrumb-home">home</mat-icon>
        </span>
      </ng-container>
    </ng-container>
  </xng-breadcrumb>

  <!-- Separador para breadcrumb -->
  <ng-template #iconTemplate>
    <!-- <mat-icon class="breadcrumb-separator">chevron_right</mat-icon> -->
    <span>/</span>
  </ng-template>
</div>
