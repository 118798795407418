import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { PericiaDto } from '@models/pericia/periciaDto.model';
import { PericiaFilter } from '@models/pericia/periciaFilter.model';
import { EstadoPericiaService } from '@services/estadoPericia/estadoPericia.service';
import { PericiaService } from '@services/pericia/pericia.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoPericiaService } from '@services/tipoPericia/tipoPericia.service';
import { DialogDeleteComponent } from 'src/app/component/dialog-delete/dialog-delete.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-list-pericia',
  templateUrl: './list-pericia.component.html',
  styleUrls: ['./list-pericia.component.css']
})
export class ListPericiaComponent {

  @Input() idExpediente!: number
  data = false
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<PericiaDto>();
  formBuilder: FormBuilder = new FormBuilder;
  formFilter: any;
  periciaFilter = new PericiaFilter
  checkedFiltroAvanzado: boolean = false;
  tiposPericia!: BaseDto[];
  estadosPericia!: BaseDto[];

  constructor(
    private PericiaService: PericiaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private estadoPericiaService: EstadoPericiaService,
    private tipoPericiaService: TipoPericiaService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["expediente", "accion"] :
        ["expediente", "lugar","fechaPropuesta", "fechaRealizada", "estadoPericia", "notificacionRealizada", "accion"];
    });

    this.formFilter = this.formBuilder.group({
      TipoPericia: [''],
      EstadoPericia: [''],
      Notificado: [false],
      Lugar: [''],
      FechaAltaDesde: [''],
      FechaAltaHasta: [''],
      FechaPropuestaDesde: [''],
      FechaPropuestaHasta: [''],
    })
  }

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

  }

  ngOnInit(): void {
    this.getAllEstadosPericia();
    this.getAllTiposPericia();
    if (this.idExpediente)
      this.getAllPericiasByIdExpediente(this.idExpediente)
  }

  filtrar() {


    this.periciaFilter.idTipoPericia = isFalsy(this.formFilter.controls['TipoPericia'].value) ? null : this.formFilter.controls['TipoPericia'].value;

    this.periciaFilter.idEstadoPericia = isFalsy(this.formFilter.controls['EstadoPericia'].value) ? null : this.formFilter.controls['EstadoPericia'].value;

    this.periciaFilter.notificado = isFalsy(this.formFilter.controls['Notificado'].value) ? null : this.formFilter.controls['Notificado'].value;

    this.periciaFilter.lugar = isFalsy(this.formFilter.controls['Lugar'].value) ? null : this.formFilter.controls['Lugar'].value;

    this.periciaFilter.fechaAltaDesde = isFalsy(this.formFilter.controls['FechaAltaDesde'].value) ? null : this.formFilter.controls['FechaAltaDesde'].value;

    this.periciaFilter.fechaAltaHasta = isFalsy(this.formFilter.controls['FechaAltaHasta'].value) ? null : this.formFilter.controls['FechaAltaHasta'].value;

    this.periciaFilter.fechaPropuestaDesde = isFalsy(this.formFilter.controls['FechaPropuestaDesde'].value) ? null : this.formFilter.controls['FechaPropuestaDesde'].value;

    this.periciaFilter.fechaPropuestaHasta = isFalsy(this.formFilter.controls['FechaPropuestaHasta'].value) ? null : this.formFilter.controls['FechaPropuestaHasta'].value;

    this.getAllPericiasByFilter( this.periciaFilter)
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllPericiasByFilter(periciaFilter:PericiaFilter) {
    this.spinner.show('spListado')
    this.PericiaService.getAllByFilter(periciaFilter)
      .subscribe(
        data => {
          this.spinner.hide('spListado')
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide('spListado')
          this.snackBar.showError(error, "Error");
        })
  }

  getAllPericiasByIdExpediente(idExpediente: number) {
    this.spinner.show('spListado')
    this.PericiaService.getAllByIdExpediente(idExpediente)
      .subscribe(
        data => {
          this.spinner.hide('spListado')
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide('spListado')
          this.snackBar.showError(error, "Error");
        })
  }

  getAllTiposPericia() {
    this.spinner.show("spBusquedaTipoPericia")
    this.tipoPericiaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTipoPericia")
          let todos = new BaseDto
          todos.id = 0
          todos.descripcion = "Todos"
          data.push(todos)
          this.tiposPericia = data
        },
        error => {
          this.spinner.hide("spBusquedaTipoPericia")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllEstadosPericia() {
    this.spinner.show("spBusquedaEstadoPericia")
    this.estadoPericiaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaEstadoPericia")
          let todos = new BaseDto
          todos.id = 0
          todos.descripcion = "Todos"
          data.push(todos)
          this.estadosPericia = data
        },
        error => {
          this.spinner.hide("spBusquedaEstadoPericia")
          this.snackBar.showError(error, "Error");
        })
  }

  openDialog(action: any, obj: any) {
    obj.action = action;
    obj.descripcion = obj.expediente.caratula
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }

  filtrosAvanzados(event: any) {
    if (event.checked == true)
      this.checkedFiltroAvanzado = true
    else
      this.checkedFiltroAvanzado = false



  }

  deleteRowData(id: number) {
    this.spinner.show('spListado')
    this.PericiaService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide('spListado')
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          this.getAllPericiasByFilter( this.periciaFilter);
        },
        error => {
          this.spinner.hide('spListado')
          this.snackBar.showError(error, "Error");
        });
  }
  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
}
