import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '@modules/shared.module';
import { ButtonGobackComponent } from '../button-goback/button-goback.component';

@Component({
  selector: 'app-go-back-and-title',
  standalone: true,
  imports: [SharedModule,ButtonGobackComponent],
  templateUrl: './go-back-and-title.component.html',
  styleUrls: ['./go-back-and-title.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoBackAndTitleComponent {
  @Input() titulo!:string
}
