<!-- <mat-grid-list cols="1" rowHeight="4rem">
  <mat-grid-tile [colspan]="1" [rowspan]="1" class="header-title">
      <div class="header-content">
        <div class="left-section">
          <app-button-goback></app-button-goback>
          <span class="title-text">{{titulo}}</span>
        </div>
      </div>
  </mat-grid-tile>
</mat-grid-list> -->

<div class="header-container">
  <div class="header-content">
    <!-- Botón de regreso alineado a la izquierda -->
    <div class="left-section">
      <app-button-goback></app-button-goback>
      <span class="title-text">{{ titulo }}</span>
    </div>
  </div>
</div>
